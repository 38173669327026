import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { Chip } from '@material-ui/core';
import { CancelRounded } from '@material-ui/icons';

class PageNotFound extends Component {
    render() {

        return (
            <div>
                <Card style={{ padding: 32 }}>
                    <h1><CancelRounded /> Грешка 404</h1>
                    <p>Страницата не е открита.</p>
                    
                </Card>
            </div>
        )
    }
}

export default PageNotFound;