const validate = values => {
    const errors = {}
    const requiredFields = [
        'companyName',
        'eik',
        'managerName',
        'companyAddress',
        'contactPersonName',
        'phone'
    ]

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Полето е задължително'
        }
    });

    return errors
}

export default validate