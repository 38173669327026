import React, { Fragment } from 'react';
import Dropzone from 'react-dropzone';
import { FormHelperText, Grid } from '@material-ui/core';
import { getBlobFromBase64 } from './getBlobFromBase64';
import { CloudUpload } from '@material-ui/icons';

export const renderMultiDropzoneImageField = (field) => {

    return (
        <Grid container>
            <Grid item xs={12} sm={5}>
                <Dropzone
                    name={field.name}
                    accept="image/jpg,image/jpeg,image/png,application/pdf"
                    multiple={true}
                    onDropAccepted={(filesToUpload, e) => field.input.onChange(filesToUpload) }
                    style={{
                        border: '1px solid #333',
                        backgroundColor: '#ccc',
                        padding: '10px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <CloudUpload />
                    <div>Качване на снимки и файлове, позволените формати са: *.jpg, *.jpeg, *.png, *.pdf</div>
                </Dropzone>
            </Grid>
            <Grid item xs={12} sm={4}>
                                              
            </Grid>
        </Grid>
    );
}