import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { Grid, Paper, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { authenticationActionCreators } from "../../actions/authentication/authenticationActionCreators";
import StudentBaseForm from "./forms/StudentBaseForm";
import StudentDetailsForm from "./forms/StudentDetailsForm";

const styles = (theme) => ({
	appBar: {
		position: "relative",
	},
	layout: {
		display: "flex",
        flexDirection: "row",
        justifyContent: "center",
	},
	paper: {
		marginTop: theme.spacing.unit * 3,
		marginBottom: theme.spacing.unit * 3,
		padding: theme.spacing.unit * 2,
		[theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
			marginTop: theme.spacing.unit * 6,
			marginBottom: theme.spacing.unit * 6,
			padding: theme.spacing.unit * 3,
		},
	},
    section: {
        padding: theme.spacing.unit * 2,
        
    },
	stepper: {
		padding: `${theme.spacing.unit * 3}px 0 ${theme.spacing.unit * 5}px`,
	},
	buttons: {
		display: "flex",
		justifyContent: "flex-end",
	},
	button: {
		marginTop: theme.spacing.unit * 3,
		marginLeft: theme.spacing.unit,
	},
});

class StudentRegistration extends Component {
	constructor(props) {
		super(props);
		this.state = {
			page: 1,
		};

		this.nextPage = this.nextPage.bind(this);
		this.previousPage = this.previousPage.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentWillMount() {
		this.props.getUniversities();
	}

	nextPage() {
		this.setState({ page: this.state.page + 1 });
	}

	previousPage() {
		this.setState({ page: this.state.page - 1 });
	}

	handleSubmit() {
		this.props.registerStudent();
	}

	render() {
		const { classes } = this.props;
		const { page } = this.state;

		return (
			<React.Fragment>
				<main className={classes.layout}>
					<Paper className={classes.paper}>
						<Typography variant="h4" align="center">
							Регистрация на студент
						</Typography>
						<Grid container spacing={24}>
							<Grid item xs={12} md={6} className={classes.section}>
								<StudentBaseForm onSubmit={this.nextPage} />
							</Grid>
							<Grid item xs={12} md={6} className={classes.section}>
								<StudentDetailsForm
									previousPage={this.previousPage}
									onSubmit={this.handleSubmit}
									universities={this.props.universities}
								/>
							</Grid>
						</Grid>
					</Paper>
				</main>
			</React.Fragment>
		);
	}
}

export default withStyles(styles)(
	connect(
		(state) => state.authentication,
		(dispatch) => bindActionCreators(authenticationActionCreators, dispatch)
	)(StudentRegistration)
);
