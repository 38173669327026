import React, { Component, Fragment } from 'react';
import { Grid, Button, InputLabel } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { Field, reduxForm } from 'redux-form';
import validate from './validations/employerDetailsValidation';
import { renderTextField, renderSingleDropzoneImageField, renderSingleCheckboxField } from '../../../../helpers';

const styles = theme => ({
});

class EmployerDetailsForm extends Component {

    render() {
        const { handleSubmit, userType } = this.props;

        console.log(this.props);

        return (
            <form onSubmit={handleSubmit}>
                <React.Fragment>
                    <Grid container spacing={8} style={{ marginTop: 40, marginBottom: 10 }}>
                        <Grid item xs={12} md={6}>
                            <Field
                                name="companyName"
                                fullWidth
                                component={renderTextField}
                                label="Име на компанията"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Field
                                name="eik"
                                fullWidth
                                component={renderTextField}
                                label="ЕИК по БУЛСТАТ"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                name="managerName"
                                fullWidth
                                component={renderTextField}
                                label="Лице представляващо компанията"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                name="companyAddress"
                                fullWidth
                                component={renderTextField}
                                label="Седалище и адрес на управление"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                name="siteURL"
                                fullWidth
                                component={renderTextField}
                                label="Интернет страница"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel>Лого на компанията</InputLabel>
                            <Field
                                name="companyLogoFile"
                                component={renderSingleDropzoneImageField} />
                        </Grid>
                        {/* {userType === "admin" &&
                            <Fragment>
                                <Grid item xs={12}>
                                    <InputLabel>Има ли споразумение</InputLabel>
                                    <Field
                                    name="hasAgreement"
                                    component={renderSingleCheckboxField} />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabel>Дата на споразумението</InputLabel>
                                    <Field
                                        name="agreementStartDate"
                                        type="date"
                                        component={renderTextField} />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabel>Край на споразумението</InputLabel>
                                    <Field
                                        name="agreementEndDate"
                                        type="date"
                                        component={renderTextField} />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabel>Университетски координатор - име</InputLabel>
                                    <Field
                                        name="agreementCoordinatorName"
                                        component={renderTextField} />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabel>Университетски координатор - и-мейл</InputLabel>
                                    <Field
                                        name="agreementCoordinatorEmail"
                                        component={renderTextField} />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabel>Има ли план за действие</InputLabel>
                                    <Field
                                    name="agreementHasActionPlan"
                                    component={renderSingleCheckboxField} />
                                </Grid>
                            </Fragment>
                        } */}
                        <Grid item xs={12} align="right">
                            <Button
                                variant="contained"
                                type="submit"
                                color="primary"
                                align="inline"
                            >
                                Редактирай
                        </Button>
                        </Grid>
                    </Grid>
                </React.Fragment>
            </form>
        )
    }
}

export default withStyles(styles)(reduxForm({
    form: 'EmployerDetailsForm', // a unique identifier for this form
    destroyOnUnmount: true, // preserve form data
    validate,
    enableReinitialize: true
})(EmployerDetailsForm));