import React from "react";
import Grid from "@material-ui/core/Grid";
import NavBar from "./NavBar";
import Footer from "./Footer";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { withStyles } from "@material-ui/core/styles";
import Notifier from "../helpers/Notifier";

const styles = (theme) => ({
	mainContent: {
		[theme.breakpoints.down("sm")]: {
			padding: 0,
		},
        [theme.breakpoints.up("sm")]: {
            padding: 16,
        },
        [theme.breakpoints.up("lg")]: {
            padding: 32,
        },
	},
});

class Layout extends React.Component {
	render() {
		const props = this.props;
		const { classes } = this.props;

		return (
			<Grid container spacing={0}>
				<Grid item xs={12}>
					<NavBar />
					<Notifier />
				</Grid>
				<Grid item xs={12} className={classes.mainContent} style={{backgroundColor:'#ddd'}}>
					{props.children}
				</Grid>

				<Grid item xs={12}>
					<Footer />
				</Grid>

				<CookieConsent
					buttonText="Съгласен съм"
					buttonStyle={{ backgroundColor: "#066" }}
					declineButtonText="Не съм съгласен"
					enableDeclineButton
					declineButtonStyle={{ backgroundColor: "#666" }}
				>
					Използваме бисквитки за нуждите на функционирането на сайта.{" "}
					<a href="#" style={{ color: "#efefef" }}>
						Повече информация
					</a>
				</CookieConsent>
			</Grid>
		);
	}
}

export default withStyles(styles)(Layout);
