import {
	Button,
	CircularProgress,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
	Typography,
	withStyles,
} from "@material-ui/core";
import {
	BusinessCenterRounded,
	NetworkCellRounded,
	QuestionAnswerRounded,
	SearchRounded,
} from "@material-ui/icons";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { authenticationActionCreators } from "../../actions/authentication/authenticationActionCreators";
import { employmentActionCreators } from "../../actions/employmentActionCreators";
import EmploymentSearchResults from "./EmploymentSearchResults";
import { Radio, RadioGroup, FormControlLabel } from "@material-ui/core";

const styles = (theme) => {};

class EmploymentDashboard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			university: "university1",
			searchText: "",
			queryText: "",
		};
		this.handleUniversityChange = this.handleUniversityChange.bind(this);
		this.handleSearchTextChange = this.handleSearchTextChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleUniversityChange(event) {
		this.setState({ university: event.target.value });
	}

	handleSearchTextChange(event) {
		this.setState({ searchText: event.target.value });
	}

	handleSubmit(event) {
		event.preventDefault();
		this.setState({ queryText: this.state.searchText });
		this.props.searchSurveys(this.state.university, this.state.searchText);
		this.props.searchData(this.state.university, this.state.searchText);
		this.props.searchMedia(this.state.university, this.state.searchText);
	}

	componentDidMount() {
		this.props.getUniversities();

		const queryParams = new URLSearchParams(this.props.location.search);
		console.log("queryParams", queryParams);
		const qUni = queryParams.get("university");
		const qQuery = queryParams.get("query");
		if (qUni) {
			this.setState({ university: qUni });
		}
		if (qQuery) {
			this.setState({ searchText: qQuery });
		}
	}

	render() {
		const { classes, history } = this.props;

		return (
			<div className={classes.root}>
				<h3>Търсене на студенти</h3>
				<Grid container spacing={8}>
					<Grid item xs={12}>
						<Paper style={{ height: "100%", padding: 24 }}>
							<form onSubmit={this.handleSubmit} style={{ marginBottom: 24 }}>
								<Grid container spacing={8} alignItems="center">
									<Grid item xs={12} md={4}>
										<FormControl component="fieldset" fullWidth>
											<RadioGroup
												aria-label="university"
												name="university"
												value={this.state.university}
												onChange={this.handleUniversityChange}
											>
												{this.props.universities &&
													this.props.universities.map((u) => (
														<FormControlLabel
															key={u.universityId}
															value={u.name}
															control={<Radio />}
															label={u.name}
														/>
													))}
											</RadioGroup>
										</FormControl>
									</Grid>
									<Grid item xs={12} md={4}>
										<TextField
											id="search-text"
											label="Име или други студентски данни"
											variant="outlined"
											fullWidth
											value={this.state.searchText}
											onChange={this.handleSearchTextChange}
										/>
									</Grid>
									<Grid item xs={3}>
										<Button
											variant="contained"
											color="primary"
											size="large"
											fullWidth
											type="submit"
										>
											<SearchRounded />
											Търсене
										</Button>
									</Grid>
								</Grid>
							</form>

							<EmploymentSearchResults
								searchText={this.state.searchText}
							></EmploymentSearchResults>
						</Paper>
					</Grid>
				</Grid>
			</div>
		);
	}
}

export default withStyles(styles)(
	connect(
		(state) => ({
			...state.employment,
			...state.authentication,
		}),
		(dispatch) =>
			bindActionCreators(
				{
					...employmentActionCreators,
					...authenticationActionCreators,
				},
				dispatch
			)
	)(EmploymentDashboard)
);
