import React from "react";
import { connect } from "react-redux";
import Login from "./authentication/Login";
import News from "./News";
import Grid from "@material-ui/core/Grid";
import Statistic from "./Statistic";
import Storybook from "./Storybook";

const Home = (props) => (
	<Grid container spacing={16}>
		<Grid item xs={12} sm={3}>
			<Grid container spacing={16}>
				<Grid item xs={12}>
					<Login />
				</Grid>
			</Grid>
		</Grid>
		<Grid item xs={12} sm={9}>
			<Grid container spacing={16}>
				<Grid item xs={12}>
					<Storybook />
				</Grid>
				<Grid item xs={12}>
					<News />
				</Grid>
			</Grid>
		</Grid>
	</Grid>
);
export default connect()(Home);
