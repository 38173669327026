import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
	Grid,
	Paper,
	TextField,
	Typography,
	withStyles,
} from "@material-ui/core";
import { employmentActionCreators } from "../../actions/employmentActionCreators";
import CSVReader from "react-csv-reader";
import {
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from "@material-ui/core";
import { authenticationActionCreators } from "../../actions/authentication/authenticationActionCreators";

const styles = (theme) => ({
	cell: {
		wordBreak: "break-all",
	},
});

const availableFields = [
	{ label: "Тип съдържание", value: "type" },
	{ label: "Дата", value: "date" },
	{ label: "Заглавие", value: "title" },
	{ label: "Текст", value: "text" },
	{ label: "URL", value: "url" },
	{ label: "Източник", value: "source" },
];

class UploadMedia extends Component {
	constructor(props) {
		super(props);

		this.state = {
			csvData: [],
			columnMappings: {},
			university: "",
		};

		this.handleCsvFileUpload = this.handleCsvFileUpload.bind(this);
		this.handleFieldSelection = this.handleFieldSelection.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentWillMount() {
		this.props.getUniversities();
	}

	handleCsvFileUpload(data) {
		this.setState({ csvData: data });
	}

	handleFieldSelection(field, columnIndex) {
		const { columnMappings } = this.state;
		this.setState({
			columnMappings: { ...columnMappings, [field]: columnIndex },
		});
	}

	handleSubmit(e) {
		e.preventDefault();
		console.log("Submitting data:", this.state);
        this.props.createMedia(this.state);
	}

	render() {
		const { csvData, columnMappings } = this.state;
		const { classes } = this.props;

		return (
			<div className={classes.root}>
				<form onSubmit={this.handleSubmit}>
					<Grid container spacing={24}>
						<Grid item xs={12}>
							<h2>Данни от Интернет източници</h2>
						</Grid>
						<Grid item xs={12}>
							<Select
								label="Университет"
								fullWidth
                                value={this.state.university}
								onChange={(e) => this.setState({ university: e.target.value })}
							>
								{this.props.universities && this.props.universities.map((u) => (
									<MenuItem key={u.universityId} value={u.name}>
										{u.name}
									</MenuItem>
								))}
							</Select>
						</Grid>
						<Grid item xs={12}>
							<h3>Моля, прикачете файл с данни от Интернет източници в CSV формат:</h3>

							<CSVReader
								onFileLoaded={this.handleCsvFileUpload}
								parserOptions={{
									delimiter: ";",
									skipEmptyLines: true,
								}}
							></CSVReader>
						</Grid>
						<Grid item xs={12}>
							{csvData.length > 0 && (
								<div>
									<Paper style={{ padding: 24 }}>
										<h3>Преглед на данните:</h3>
										<p>
											Общо има {csvData.length - 1} записа. Тук са показани
											първите 5 реда от файла с цел проверка на коректното му
											прочитане.
										</p>
										<Table border={1}>
											<TableHead>
												<TableRow>
													{csvData[0].map((column, index) => (
														<TableCell
															key={index}
															className={classes.cell}
															style={{
																fontWeight: 700,
																backgroundColor: "#efefef",
															}}
														>
															{column}
														</TableCell>
													))}
												</TableRow>
											</TableHead>
											<TableBody>
												{csvData
													.slice(1, csvData.length > 6 ? 6 : csvData.length)
													.map((row, index) => (
														<TableRow key={index}>
															{row.map((column, index) => (
																<TableCell key={index} className={classes.cell}>
																	{column.length > 100
																		? column.substring(0, 100)
																		: column}
																</TableCell>
															))}
														</TableRow>
													))}
											</TableBody>
										</Table>
									</Paper>

									<Paper style={{ padding: 24, marginTop: 40 }}>
										<h3>Посочете съответствията на колоните от файла:</h3>
										{availableFields.map((field) => (
											<div key={field.value} style={{ width: "400px" }}>
												<FormControl fullWidth>
													<InputLabel>{field.label}</InputLabel>
													<Select
														onChange={(event) =>
															this.handleFieldSelection(
																field.value,
																event.target.value
															)
														}
														value={
															columnMappings[field.value] === undefined
																? ""
																: columnMappings[field.value]
														}
													>
														<MenuItem value={-1}>Ignore</MenuItem>
														{csvData[0].map((column, index) => (
															<MenuItem key={index} value={index}>
																{column}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											</div>
										))}
										<div style={{ marginTop: 10 }}>
											<Button variant="contained" color="primary" type="submit">
												Качване
											</Button>
										</div>
									</Paper>
								</div>
							)}
						</Grid>
					</Grid>
				</form>
			</div>
		);
	}
}

export default withStyles(styles)(
	connect(
		(state) => ({
			...state.employment,
			...state.authentication,
		}),
		(dispatch) =>
			bindActionCreators(
				{
					...employmentActionCreators,
					...authenticationActionCreators,
				},
				dispatch
			)
	)(UploadMedia)
);
