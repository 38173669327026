import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core";
import { jobsActionCreators } from "../../actions/jobsActionCreators";
import JobForm from "./forms/JobForm";
import { getLoggedUser } from "../../helpers";
import { authenticationActionCreators } from "../../actions/authentication/authenticationActionCreators";

const styles = (theme) => ({});

class EditOffer extends Component {
	constructor(props) {
		super(props);

		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleImageDrop = this.handleImageDrop.bind(this);
	}

	componentDidMount() {
		this.props.getOffer(this.props.match.params.id);
		this.props.getCategories();
		this.props.getUniversities();
	}

	handleSubmit = (e) => {
		this.props.editOffer(this.props.currentOffer.imagesNames);
	};

	handleImageDrop = (e, filesToUpload) => {
		this.props.uploadImages(filesToUpload);
	};

	render() {
		const currentOffer = this.props.currentOffer;
		const loggedUser = getLoggedUser();

		if (
			currentOffer.userId !== loggedUser.id &&
			loggedUser.userType !== "admin"
		) {
			return null;
		} else {
			return (
				<div>
					<h3>Редактиране на обява</h3>
					<JobForm
						initialValues={{
							id: currentOffer.id,
							title: currentOffer.title,
							content: currentOffer.content,
							typeId: currentOffer.typeId,
							userId: currentOffer.userId,
							createdOn: currentOffer.createdOn,
							expirationDate: currentOffer.expirationDate,
							imagesNames: currentOffer.imagesNames,
							imagesFiles: currentOffer.imagesFiles,
							jobCategoriesIds: currentOffer.jobCategories && currentOffer.jobCategories.map(x => x.id) || [],
                            universitiesIds: currentOffer.universities && currentOffer.universities.map(x => x.universityId) || [],
						}}
						imagesNames={currentOffer.imagesNames}
						userId={loggedUser.id}
						categories={this.props.categories}
						universities={this.props.universities}
						folder="jobsOffersImages"
						onSubmit={this.handleSubmit}
						onDrop={this.handleImageDrop}
					/>
				</div>
			);
		}
	}
}

export default withStyles(styles)(
	connect(
		(state) => ({
			...state.jobs,
			...state.authentication,
		}),
		(dispatch) =>
			bindActionCreators(
				{ ...jobsActionCreators, ...authenticationActionCreators },
				dispatch
			)
	)(EditOffer)
);
