import React, { Component } from "react";
import {
	Button,
	CircularProgress,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	withStyles,
} from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { employmentActionCreators } from "../../actions/employmentActionCreators";
import { authenticationActionCreators } from "../../actions/authentication/authenticationActionCreators";
import Moment from "react-moment";

const styles = (theme) => ({
	th: {
		backgroundColor: theme.palette.secondary.light,
		fontWeight: "bold",
	},
	searchHeader: {
		backgroundColor: theme.palette.primary.main,
		color: "#fff",
		textAlign: "left",
		padding: "12px",
		margin: 0,
	},
	longText: {
		wordBreak: "break-all",
		padding: "24px",
	},
	highlight: {
		backgroundColor: "pink",
	},
});

class EmploymentSearchResults extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			expandedRows: [],
		};
	}

	toggleRowExpansion = (rowId) => {
		const { expandedRows } = this.state;
		const isRowExpanded = expandedRows.includes(rowId);

		if (isRowExpanded) {
			this.setState({
				expandedRows: expandedRows.filter((id) => id !== rowId),
			});
		} else {
			this.setState({
				expandedRows: [...expandedRows, rowId],
			});
		}
	};

	render() {
		const { classes } = this.props;
		const { expandedRows } = this.state;

		return (
			<>
				{this.props.isSearching && <CircularProgress size={64} />}
				{!this.props.isSearching &&
					this.props.surveys &&
					this.props.surveys.length > 0 && (
						<>
							<h4 className={classes.searchHeader}>Анкети</h4>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell className={classes.th}>ЕГН</TableCell>
										<TableCell className={classes.th}>
											Факултетен номер
										</TableCell>
										<TableCell className={classes.th}>Име</TableCell>
										<TableCell className={classes.th}>Компания</TableCell>
										<TableCell className={classes.th}>Адрес</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{this.props.surveys.map((survey) => (
										<TableRow key={survey.id}>
											<TableCell>{survey.egn}</TableCell>
											<TableCell>{survey.fn}</TableCell>
											<TableCell>{survey.name}</TableCell>
											<TableCell>
												({survey.eik}) {survey.companyName}
											</TableCell>
											<TableCell>{survey.companyAddress}</TableCell>
										</TableRow>
									))}
									<TableRow></TableRow>
								</TableBody>
							</Table>
						</>
					)}

				{!this.props.isSearching &&
					this.props.data &&
					this.props.data.length > 0 && (
						<>
							<h4 className={classes.searchHeader}>Резултати от НОИ</h4>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell className={classes.th}>Източник</TableCell>
										<TableCell className={classes.th}>ЕГН</TableCell>
										<TableCell className={classes.th}>Име</TableCell>
										<TableCell className={classes.th}>ЕИК</TableCell>
										<TableCell className={classes.th}>Компания</TableCell>
										<TableCell className={classes.th}>Адрес</TableCell>
										<TableCell className={classes.th}>Година</TableCell>
									</TableRow>
								</TableHead>

								<TableBody>
									{this.props.data.map((d) => (
										<TableRow key={d.id}>
											<TableCell>{d.source}</TableCell>
											<TableCell>{d.egn}</TableCell>
											<TableCell>{d.name}</TableCell>
											<TableCell>{d.eik}</TableCell>
											<TableCell>{d.companyName}</TableCell>
											<TableCell>{d.companyAddress}</TableCell>
											<TableCell>{d.year}</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</>
					)}

				{!this.props.isSearching &&
					this.props.media &&
					this.props.media.length > 0 && (
						<>
							<h4 className={classes.searchHeader}>Резултати от Интернет</h4>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell className={classes.th}>Източник</TableCell>
										<TableCell className={classes.th}>Дата</TableCell>
										<TableCell className={classes.th}>Заглавие</TableCell>
                                        <TableCell className={classes.th}></TableCell>
									</TableRow>
								</TableHead>

								<TableBody>
									{this.props.media.map((d) => {
										const parts = this.props.searchText
											? d.articleText.split(
													new RegExp(`(${this.props.searchText})`, "gi")
											  )
											: null;

										const isRowExpanded = expandedRows.includes(d.id);

										return (
											<React.Fragment key={d.id}>
												<TableRow>
													<TableCell>{d.source}</TableCell>
													<TableCell>
														<Moment format="DD.MM.YYYY г.">
															{d.articleDate}
														</Moment>
													</TableCell>
													<TableCell>
														<a href={d.websiteUrl} target="_blank">
															{d.articleTitle}
														</a>
													</TableCell>
													<TableCell>
														<Button
															onClick={() => this.toggleRowExpansion(d.id)}
                                                            variant="outlined"
														>
															{isRowExpanded ? "скрий текста" : "виж текста"}
														</Button>
													</TableCell>
												</TableRow>
												{isRowExpanded && (
													<TableRow>
														<TableCell colSpan={4} className={classes.longText}>
															{parts &&
																parts.map((part, i) => (
																	<span
																		key={i}
																		className={
																			part.toLowerCase() ===
																			this.props.searchText.toLowerCase()
																				? classes.highlight
																				: ""
																		}
																	>
																		{part}
																	</span>
																))}
															{!parts && d.articleText}
														</TableCell>
													</TableRow>
												)}
											</React.Fragment>
										);
									})}
								</TableBody>
							</Table>
						</>
					)}
			</>
		);
	}
}

export default withStyles(styles)(
	connect(
		(state) => ({
			...state.employment,
			...state.authentication,
		}),
		(dispatch) =>
			bindActionCreators(
				{
					...employmentActionCreators,
					...authenticationActionCreators,
				},
				dispatch
			)
	)(EmploymentSearchResults)
);
