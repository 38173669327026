import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { contentActionCreators } from "../actions/contentActionCreators";
import { bindActionCreators } from "redux";
import { LinkContainer } from "react-router-bootstrap";
import { CircularProgress } from "@material-ui/core";
import { getLoggedUser } from "../helpers";

const styles = (theme) => ({
	card: {
		marginBottom: 24,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',

	},
    overlay: {
        backgroundColor: 'rgba(0,0,0,0.5)',
        color: 'white',
        padding: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 8,
        "& h5, & p": {
            color: 'white',
        },
    },
	media: {
		height: 100,
	},
	heading: {
		color: theme.palette.primary.dark,
        textTransform: 'uppercase',
	},
});

class News extends Component {
	componentWillMount() {
		this.props.requestNews();
	}

	render() {
		return (
			<div>
				{this.props.isLoading === false ? (
					NewsElements(this.props)
				) : (
					<CircularProgress />
				)}
			</div>
		);
	}
}

function NewsElements(props) {
	const { classes } = props;
	const loggedUser = getLoggedUser();
	const adminSignedIn =
		loggedUser && loggedUser.token && loggedUser.userType === "admin"
			? true
			: false;

    const formatDate = (d) => {
        const date = new Date(d);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
    };

	return (
		<div>
			<Typography variant="h5" gutterBottom className={classes.heading}>
				Новини
			</Typography>
			{props.news.length > 0 ? (
				props.news.map((article) => (
					<div key={article.id} className={classes.card} style={{ backgroundImage: `url('${article.heroImage || "/img/untitled5.jpg"}')` }}>
						<LinkContainer to={`/page/${article.id}`}>
							<CardActionArea>
								<CardContent className={classes.overlay}>
									<Typography gutterBottom variant="h5">
										{article.title}
									</Typography>
									<Typography component="p">{formatDate(article.date)}</Typography>
								</CardContent>
							</CardActionArea>
						</LinkContainer>
						<CardActions>
							<LinkContainer to={`/page/${article.id}`} exact>
								<Button size="small" color="primary" variant="contained">
									подробности
								</Button>
							</LinkContainer>
						</CardActions>
					</div>
				))
			) : (
				<div>Няма актуални новини.</div>
			)}
			
			
		</div>
	);
}

export default withStyles(styles)(
	connect(
		(state) => state.content,
		(dispatch) => bindActionCreators(contentActionCreators, dispatch)
	)(News)
);
