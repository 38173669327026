import React, { Component } from "react";
import { MenuItem, Button, Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Field, reduxForm } from "redux-form";
import validate from "./validations/studentRegValidate";
import asyncValidate from "./validations/asyncValidate";
import {
	renderTextField,
	renderSelectField,
	renderError,
} from "../../../helpers";

const styles = (theme) => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	margin: {
		width: 550,
	},
	withoutLabel: {
		marginTop: theme.spacing.unit * 3,
	},
	textField: {
		flexBasis: 200,
	},
});

class StudentDetailsForm extends Component {
	render() {
		const {
			handleSubmit,
			pristine,
			previousPage,
			submitting,
			universities,
		} = this.props;

		return (
			<React.Fragment>
				<Typography
					variant="h6"
					gutterBottom
					align="center"
					style={{
						fontWeight: "bold",
						margin: 24,
						borderBottom: "1px solid #ccc",
					}}
				>
					Профил на студента
				</Typography>
				<Grid container spacing={24}>
					<Grid item xs={12}>
						<Field
							name="facultyNumber"
							component={renderTextField}
							label="Факултетен номер"
						/>
					</Grid>
					<Grid item xs={12}>
						<Field
							name="degree"
							component={renderSelectField}
							label="Образователно-квалификационна степен"
						>
							<MenuItem value="Бакалавър">Бакалавър</MenuItem>
							<MenuItem value="Професионален бакалавър">
								Професионален бакалавър
							</MenuItem>
							<MenuItem value="Магистър">Магистър</MenuItem>
						</Field>
					</Grid>
					<Grid item xs={12}>
						<Field
							name="universityId"
							component={renderSelectField}
							label="Висше училище"
						>
							{universities &&
								universities.map((x) => (
									<MenuItem key={x.id} value={x.id}>
										{x.name}
									</MenuItem>
								))}
						</Field>
					</Grid>
					<Grid item xs={12}>
						<Field
							name="speciality"
							component={renderTextField}
							fullWidth
							label="Специалност"
						/>
					</Grid>
					<Grid item xs={12}>
						<Field
							name="tel"
							component={renderTextField}
							label="Телефонен номер"
						/>
					</Grid>
					<Grid item xs={12}>
						<Field
							name="hasGraduated"
							component={renderSelectField}
							label="Статус"
						>
							<MenuItem value="true">Учащ</MenuItem>
							<MenuItem value="false">Завършил</MenuItem>
						</Field>
					</Grid>
					<Grid item xs={12} align="right">
						
						<Button
							variant="contained"
							type="submit"
							color="primary"
							onClick={handleSubmit}
							className="next"
							align="inline"
						>
							Регистрация
						</Button>
					</Grid>
				</Grid>
			</React.Fragment>
		);
	}
}

export default withStyles(styles)(
	reduxForm({
		form: "StudentRegistrationForm", // a unique identifier for this form
		destroyOnUnmount: false, // preserve form data
		validate,
		asyncValidate,
	})(StudentDetailsForm)
);
