import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Email, Phone, LocationOn } from "@material-ui/icons";
import { LinkContainer, Link } from "react-router-bootstrap";
import { bindActionCreators } from "redux";
import { menuActionCreators } from "../actions/menuActionCreators";
import Divider from "@material-ui/core/Divider";
import OpenMap from "./Map";
import { Facebook, Linkedin, Twitter } from "react-social-sharing";

const styles = (theme) => ({
	footer: {
		flexGrow: 1,
		backgroundImage: "linear-gradient(#999,#888)",
		paddingLeft: theme.spacing.unit * 12,
		paddingRight: theme.spacing.unit * 12,
		paddingBottom: theme.spacing.unit * 3,
		paddingTop: theme.spacing.unit * 3,
	},
	grow: {
		flexGrow: 2,
	},
	paper: {
		padding: theme.spacing.unit * 4,
		backgroundColor: "rgba(33,33,33,0.1)",
		minHeight: 160,
	},
	externalLink: {
		color: theme.palette.primary.main,
	},
	partners: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-around",
		"& a": {
			width: "18%",
		},
	},
	footerMenuHead: {
		fontWeight: "bold",
		fontSize: "1.1rem",
	},
});

class Footer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			fixed: false,
		};
	}

	componentDidMount() {
		// this.props.requestMenuElements("main");
	}

	render() {
		const props = this.props;
		const { classes } = props;

		return (
			<div className={classes.footer}>
				<Grid container spacing={24} alignItems="flex-start" justify="center">
					<Grid item xs={12} md={3}>
						<Typography variant="h6" className={classes.footerMenuHead}>
							Контакти
						</Typography>
						<Divider />
						<Typography variant="body1">
							Тракийски университет - Стара Загора
						</Typography>
						<Typography variant="body1">042/ 699 209</Typography>
						<Typography variant="body1">
							<a
								href="mailto:rector@trakia-uni.bg"
								className={classes.externalLink}
							>
								rector@trakia-uni.bg
							</a>
						</Typography>
						<Typography variant="body1">
							6000 Стара Загора Студентски град
						</Typography>
					</Grid>
					<Grid item xs={12} md={3}>
						<Typography variant="h6" className={classes.footerMenuHead}>
							Социални медии
						</Typography>
						<Divider />
						<Grid container spacing={8} alignItems="center">
							<Grid item>
								<Facebook />
							</Grid>
							<Grid item>
								<Twitter />
							</Grid>
							<Grid item>
								<Linkedin />
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} md={6}>
						<Grid
							container
							spacing={24}
							alignItems="center"
							justify="center"
							style={{ backgroundColor: "#fff", boxShadow: "1px 1px 4px #000" }}
						>
							<Grid item xs={12} md={6}>
								<img src="/img/es-logo.png" alt="Европейски социален фонд" height={80} />
							</Grid>
							<Grid item xs={12} md={6}>
								<img src="/img/noir-logo.png" alt="Наука и образование за интелигентен растеж" height={80} />
							</Grid>
						</Grid>
						<Grid
							container
							spacing={24}
							alignItems="center"
							justify="center"
							style={{ backgroundColor: "#444", color: '#fff', boxShadow: "1px 1px 4px #000" }}
						>
							<Grid item xs={12}>
								<b>Информация за проекта</b>
								<br />
								<br />
								проект „Съвместен интегриран подход за модернизация и цифрова
								трансформация на обучението в приоритетни професионални
								направления от висшето образование чрез партньорство между
								Тракийския университет, УНСС, Русенския университет и Българска
								стопанска камара (СИПМО – ТрУ)
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</div>
		);
	}
}

//Footer.propTypes = {
//    classes: PropTypes.object.isRequired,
//};

export default withStyles(styles)(
	connect(
		(state) => state.menu,
		(dispatch) => bindActionCreators(menuActionCreators, dispatch)
	)(Footer)
);
