import { contentConstants } from "../constants/contentConstants";
import { push } from "react-router-redux";
import { authHeader, getConvertedBase64Images } from "../helpers";
import {notify} from '../helpers/notification';
import { config } from "../helpers/config";

export const contentActionCreators = {
    requestArticles: () => async dispatch => {
        const url = `${config.base}api/Content/`;
        const response = await fetch(url);
        const articles = await response.json();

        dispatch({
            type: contentConstants.REQUEST_ARTICLES,
            articles
        });
    },

    getArticle: articleId => async dispatch => {
        if (!isNaN(articleId)) {
            const url = `${config.base}api/Content/${articleId}`;
            const response = await fetch(url);
            const article = await response.json();

            if (article.date) {
                article.date = article.date.split("T")[0];
            }
            if (article.dateFinish) {
                article.dateFinish = article.dateFinish.split("T")[0];
            }

            dispatch({
                type: contentConstants.REQUEST_ARTICLE_TO_READ,
                articleToRead: article
            });
        }
    },

    requestNews: () => async dispatch => {
        const url = `${config.base}api/Content/news`;
        try {
            const response = await fetch(url);
            const news = await response.json();

            dispatch({
                type: contentConstants.REQUEST_NEWS,
                news
            });
        } catch (err) { }
    },

    requestAllNews: () => async dispatch => {
        const url = `${config.base}api/Content/newsList`;
        const response = await fetch(url);
        const news = await response.json();

        dispatch({
            type: contentConstants.REQUEST_NEWS,
            news
        });
    },

    requestEvents: () => async dispatch => {
        const url = `${config.base}api/Content/events`;
        try {
            const response = await fetch(url);
            const events = await response.json();

            dispatch({
                type: contentConstants.REQUEST_EVENTS,
                events
            });
        } catch (ex) { }
    },

    requestAllEvents: () => async dispatch => {
        const url = `${config.base}api/Content/eventsList`;
        const response = await fetch(url);
        const events = await response.json();

        dispatch({
            type: contentConstants.REQUEST_EVENTS,
            events
        });
    },

    createArticleForm: () => async dispatch => {
        dispatch({
            type: contentConstants.CREATE_ARTICLE_FORM
        });
    },

    submitPublication: (imagesNames) => async (dispatch, getState) => {
        const { form } = getState();
        const articleToAdd = form.ArticleForm.values;
        const url = `${config.base}api/Content/CreateArticle`;
        let images = articleToAdd.imagesFiles;

        let base64Images = await getConvertedBase64Images(images);

      //  articleToAdd.imagesFiles = base64Images.convertedImages;
        articleToAdd.imagesNames = imagesNames;

        return fetch(url, {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(articleToAdd)
        }).then(res => {
            if (res.ok) {
                dispatch({
                    type: contentConstants.POST_SUCCESS
                });
                dispatch(push("/"));
                dispatch(
                    notify({
                        message: "Успешно добавяне на статия.",
                        status: 200
                    })
                );
            } else {
                dispatch({
                    type: contentConstants.POST_ERROR
                });
                dispatch(
                    notify({
                        message: "Възникнала е грешка, моля опитайте отново.",
                        status: 400
                    })
                );
            }
        });
    },

    editArticle: (imagesNames) => async (dispatch, getState) => {
        const { form } = getState();
        const articleToEdit = form.EditArticleForm.values;

        const url = `${config.base}api/Content/Edit`;

        let images = articleToEdit.imagesFiles;

        if (images && typeof images[0] !== "string") {
            let base64Images = await getConvertedBase64Images(images);
            //articleToEdit.imagesFiles = base64Images.convertedImages;
            articleToEdit.imagesNames = imagesNames;
        }

        return fetch(url, {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(articleToEdit)
        }).then(res => {
            if (res.ok) {
                dispatch({
                    type: contentConstants.EDIT_SUCCESS
                });
                dispatch(push("/article/" + articleToEdit.id));
                dispatch(
                    notify({
                        message: "Статията беше редактирана успешно!",
                        status: 200
                    })
                );
            } else {
                dispatch({
                    type: contentConstants.EDIT_ERROR
                });
                dispatch(
                    notify({
                        message: "Възникнала е грешка, моля опитайте отново.",
                        status: 400
                    })
                );
            }
        });
    },

    deleteArticle: articleId => async dispatch => {
        const url = `${config.base}api/Content/Delete?articleId=${articleId}`;

        return fetch(url, {
            method: "POST",
            headers: authHeader()
        }).then(res => {
            if (res.ok) {
                dispatch({
                    type: contentConstants.DELETE_SUCCESS
                });
                dispatch(push("/"));
                dispatch(
                    notify({
                        message: "Статията беше изтрита успешно!",
                        status: 200
                    })
                );
            } else {
                dispatch({
                    type: contentConstants.DELETE_ERROR
                });
                dispatch(
                    notify({
                        message: "Възникнала е грешка, моля опитайте отново.",
                        status: 400
                    })
                );
            }
        });
    },

    uploadImages: filesToUpload => async dispatch => {
        dispatch({ type: contentConstants.UPLOAD_FILE_BEGIN });

		const url = `${config.base}api/Content/UploadImages`;

		if (filesToUpload && filesToUpload.length > 0) {
			let images = filesToUpload;
			let base64Images = await getConvertedBase64Images(images);
			let imagesFiles = base64Images.convertedImages;
			let imagesNames = base64Images.imagesNames;
			const response = await fetch(url, {
				headers: authHeader(),
				method: "POST",
				body: JSON.stringify({ files: imagesFiles, names: imagesNames })
			});
            const result = await response.json();
            const status = response.status;
			if (status === 200) {
				dispatch({ type: contentConstants.UPLOAD_FILE_SUCCESS, result:result });
			} else {
                dispatch({ type: contentConstants.UPLOAD_FILE_ERROR });
                dispatch(
                    notify({
                        message: "Възникнала е грешка при качването на файл, моля опитайте отново или се свържете с администратор.",
                        status: 400
                    })
                );
			}
		}
	},
};
