import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { routerReducer, routerMiddleware } from "react-router-redux";
import { reducer as formReducer } from "redux-form";
import * as Content from "../reducers/ContentReducer";
import * as Menu from "../reducers/MenuReducer";
import * as Auth from "../reducers/AuthReducer";
import * as Profiles from "../reducers/ProfilesReducer";
import * as Jobs from "../reducers/JobsReducer";
import * as Notifier from "../reducers/NotifierReducer";
import * as Employment from "../reducers/EmploymentReducer";

export default function configureStore(history, initialState) {
	const reducers = {
		form: formReducer,
		content: Content.reducer,
		menu: Menu.reducer,
		authentication: Auth.reducer,
		profiles: Profiles.reducer,
		jobs: Jobs.reducer,
		notifications: Notifier.reducer,
		employment: Employment.reducer,
	};

	const middleware = [thunk, routerMiddleware(history)];

	// In development, use the browser's Redux dev tools extension if installed
	const enhancers = [];
	const isDevelopment = process.env.NODE_ENV === "development";
	if (
		isDevelopment &&
		typeof window !== "undefined" &&
		window.devToolsExtension
	) {
		enhancers.push(window.devToolsExtension());
	}

	const rootReducer = combineReducers({
		...reducers,
		routing: routerReducer,
	});

	return createStore(
		rootReducer,
		initialState,
		compose(applyMiddleware(...middleware), ...enhancers)
	);
}
