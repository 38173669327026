import React from 'react';
import { FormHelperText, TextField } from '@material-ui/core';

export const renderSelectField = ({ input, label, meta: { touched, error }, children, ...custom }) => (
    <div>
        <TextField
            {...input}
            select
            label={label}
            fullWidth
            variant="filled"
            onChange={(event, index, value) => input.onChange(event.target.value)}
            children={children}
            {...custom}
            {...touched && error ? <span>{error}</span> : false}
        />
        <FormHelperText error={!!error && !!touched}>{touched && error}</FormHelperText>
    </div>
)