import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import SwipeableViews from "react-swipeable-views";
import { AppBar, Tab, Tabs, Typography, Paper, Button, Grid, Snackbar, SnackbarContent } from "@material-ui/core";
import toRenderProps from "recompose/toRenderProps";
import withState from "recompose/withState";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { profileActionCreators } from "../../../actions/profileActionCreators";
import { getLoggedUser } from "../../../helpers";
import StudentBaseForm from "./forms/StudentBaseForm";
import StudentDetailsForm from "./forms/StudentDetailsForm";
import red from "@material-ui/core/colors/red";
import { authenticationActionCreators } from "../../../actions/authentication/authenticationActionCreators";

const WithState = toRenderProps(withState("anchorEl", "updateAnchorEl", null));

function TabContainer({ children, dir }) {
	return (
		<Typography component="div" dir={dir}>
			{children}
		</Typography>
	);
}

const styles = (theme) => ({
	root: {
		paddingTop: theme.spacing.unit * 2,
	},
	heading: {
		marginBottom: 40,
		marginTop: 40,
	},
	snackbar: {
		backgroundColor: red[700],
		maxWidth: "100%",
	},
});

class StudentProfile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedTab: 0,
			file: {},
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleChangeIndex = this.handleChangeIndex.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentWillMount() {
		const loggedUser = getLoggedUser();

		this.props.requestStudent(
			this.props.match.params.id === undefined ? loggedUser.id : this.props.match.params.id
		);
		this.props.getUniversities();
	}

	handleChange(event, selectedTab) {
		this.setState({ selectedTab });
	}

	handleChangeIndex(index) {
		this.setState({ selectedTab: index });
	}

	handleSubmit(formPart) {
		switch (formPart) {
			case "base":
				this.props.changePassword("student");
				break;
			case "details":
				this.props.changeStudentDetails();
				break;
		}
	}

	render() {
		const { classes, theme } = this.props;
		const loggedUser = getLoggedUser();
		const student = this.props.student;

		if ((!loggedUser && !loggedUser.token && loggedUser.userType !== "admin") || !student) {
			return null;
		} else {
			return (
				<Grid
					container
					spacing={0}
					// direction="column"
					alignItems="center"
					justify="center">
					<Grid item xs={12}>
						<Paper className={classes.root}>
							<Typography variant="h4" align="center" className={classes.heading}>
								Моят профил
							</Typography>
							<Grid item xs={12}>
								
							</Grid>
							<AppBar position="static" color="default">
								<Tabs
									value={this.state.selectedTab}
									onChange={this.handleChange}
									indicatorColor="primary"
									textColor="primary"
									fullWidth>
									<Tab label="Данни за профила" />
									<Tab label="Лични данни" />
								</Tabs>
							</AppBar>

							<SwipeableViews
								axis={theme.direction === "rtl" ? "x-reverse" : "x"}
								index={this.state.selectedTab}
								onChangeIndex={this.handleChangeIndex}>
								<TabContainer dir={theme.direction}>
									<Grid
										container
										spacing={0}
										// direction="column"
										alignItems="center"
										justify="center">
										<Grid item xs={12} sm={4}>
											<StudentBaseForm
												initialValues={{
													email: student.email,
												}}
												onSubmit={() => this.handleSubmit("base")}
											/>
										</Grid>
									</Grid>
								</TabContainer>
								<TabContainer dir={theme.direction}>
									<Grid
										container
										spacing={0}
										// direction="column"
										alignItems="center"
										justify="center">
										<Grid item xs={12} sm={6}>
											<StudentDetailsForm
												initialValues={{
													fName: student.firstName,
													mName: student.middleName,
													lName: student.lastName,
													email: student.email,
													facultyNumber: student.facultyNumber,
													degree: student.degree,
													hasGraduated: student.hasGraduated + "",
													universityId: student.universityId,
													speciality: student.speciality,
													tel: student.tel,
													cvFile: student.cvFile,
													autobiography: student.autobiography,
												}}
												cvName={student.autobiography}
												universities={this.props.universities}
												onSubmit={() => this.handleSubmit("details")}
											/>
										</Grid>
									</Grid>
								</TabContainer>
							</SwipeableViews>
						</Paper>
					</Grid>
				</Grid>
			);
		}
	}
}

export default withStyles(styles, { withTheme: true })(
	connect(
		(state) => ({
			...state.profiles,
			...state.authentication,
		}),
		(dispatch) => bindActionCreators({ ...profileActionCreators, ...authenticationActionCreators }, dispatch)
	)(StudentProfile)
);
