import { employmentConstants } from "../constants/employmentConstants";

const initialState = {
	isLoading: false,
	isUploading: false,
    isSearching: false,
	surveys: [],
	data: [],
	media: [],
	error: null,
};

export const reducer = (state, action) => {
	state = state || initialState;

	switch (action.type) {
		case employmentConstants.SEARCH_SURVEYS_BEGIN:
			return {
				...state,
				isSearching: true,
			};

		case employmentConstants.SEARCH_SURVEYS:
			return {
				...state,
				isSearching: false,
				surveys: action.payload,
			};
		case employmentConstants.SEARCH_DATA_BEGIN:
			return {
				...state,
				isSearching: true,
			};
		case employmentConstants.SEARCH_DATA:
			return {
				...state,
				isSearching: false,
				data: action.payload,
			};
		case employmentConstants.SEARCH_MEDIA_BEGIN:
			return {
				...state,
				isSearching: true,
			};
		case employmentConstants.SEARCH_MEDIA:
			return {
				...state,
				isSearching: false,
				media: action.payload,
			};

		default:
			return state;
	}
};
