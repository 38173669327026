import React, { Component } from "react";
import { Grid, Button, InputLabel } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Field, reduxForm, formValues } from "redux-form";
import validate from "./validateArticle";
import {
	renderTextField,
	renderTextEditor,
	renderMultiDropzoneImageField,
} from "../../../helpers";

const styles = (theme) => ({
	margin: {
		margin: theme.spacing.unit,
	},
	dateField: {
		width: 200,
	},
});

class EditArticleForm extends Component {
	render() {
		const { classes, handleSubmit, onDrop, getArticle, userId } = this.props;
		const article = getArticle;
		const path = `/uploads/articlesImages/`;

		if (article.pageTypeId === 1) {
			return (
				<div>
					<form onSubmit={handleSubmit}>
						<Grid container spacing={16}>
							<Grid item xs={12} sm={6}>
								<Field
									name="title"
									component={renderTextField}
									style={{ marginLeft: 7 }}
									margin="dense"
									fullWidth
									label="Заглавие"
								/>
								<Field
									name="heroImage"
									component={renderTextField}
									style={{ marginLeft: 7 }}
									margin="dense"
									fullWidth
									label="Снимка към публикацията"
								/>
								<Field
									name="content"
									component={renderTextEditor}
									style={{ marginLeft: 7 }}
									margin="dense"
									fullWidth
									label="Съдържание"
								/>
								<br />
								<Grid align="right">
									<Button
										variant="contained"
										type="submit"
										color="primary"
										className={classes.menuButton}
									>
										Редактирай
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</form>
				</div>
			);
		} else {
			return (
				<div>
					<form onSubmit={handleSubmit}>
						<Grid container spacing={16}>
							<Grid item xs={12} sm={8}>
								<Grid container spacing={16}>
									<Grid item xs={4}>
										<Field
											name="date"
											component={renderTextField}
											type="date"
											className={classes.dateField}
											label="Дата на събитието"
											InputLabelProps={{
												shrink: true,
											}}
										/>
									</Grid>
									<Grid item xs={4}>
										<Field
											name="dateFinish"
											component={renderTextField}
											type="date"
											className={classes.dateField}
											label="Крайна дата"
											InputLabelProps={{
												shrink: true,
											}}
										/>
									</Grid>
									<Grid item xs={4}>
										<Field
											name="eventLocation"
											component={renderTextField}
											style={{ marginLeft: 7 }}
											margin="dense"
											className={classes.eventLocation}
											label="Място на събитието"
										/>
									</Grid>
									<Grid item xs={12}>
										<Field
											name="title"
											component={renderTextField}
											style={{ marginLeft: 7, fontWeight: "bold" }}
											margin="dense"
											fullWidth
											label="Заглавие"
										/>
									</Grid>
									<Grid item xs={12}>
										<Field
											name="heroImage"
											component={renderTextField}
											style={{ marginLeft: 7 }}
											margin="dense"
											fullWidth
											label="Снимка към публикацията"
										/>
									</Grid>
									<Grid item xs={12}>
										<InputLabel>Снимки</InputLabel>
										<Field
											name="imagesFiles"
											folderName="articleImages"
											userId={userId}
											component={renderMultiDropzoneImageField}
											onChange={onDrop}
										/>
										{article.imagesNames && Array.isArray(article.imagesNames) && (
											<div>
												{article.imagesNames.map((file, i) => (
													<div key={i}>
														<img
															alt="Снимка към статията"
															style={{ width: 300 }}
															id={"article-img-" + i}
															src={`${path}/${file ? file : "ERROR"}`}
														/>
														<br />
														<input
															type="text"
															disabled
															value={`${path}/${file ? file : "ERROR"}`}
															style={{ padding: 8, width: 300 }}
														/>
													</div>
												))}
											</div>
										)}
									</Grid>
									<Grid item xs={12}>
										<Field
											name="content"
											component={renderTextEditor}
											style={{ marginLeft: 7 }}
											margin="dense"
											fullWidth
											label="Съдържание"
										/>
									</Grid>
									<Grid item xs={12}>
										<Grid align="center">
											<Button
												variant="contained"
												type="submit"
												color="primary"
												className={classes.menuButton}
											>
												Съхрани
											</Button>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							
						</Grid>
					</form>
				</div>
			);
		}
	}
}

export default withStyles(styles)(
	reduxForm({
		form: "EditArticleForm", // a unique identifier for this form
		validate,
		enableReinitialize: true,
	})(EditArticleForm)
);
