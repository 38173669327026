import React, { Component } from "react";
import { ListItem, ListItemText, Avatar, Chip } from "@material-ui/core";
import { LinkContainer } from "react-router-bootstrap";
import Moment from "react-moment";
import { CalendarToday, Domain } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const styles = (theme) => ({
	title: {
		fontSize: "1.35rem",
	},
	subtitle: {
		fontSize: "1.1rem",
        color: theme.palette.primary.main,
	},
	jobBackground: {
		backgroundColor: "#E91E63",
		color: "#FFF",
		[theme.breakpoints.up("sm")]: {
			width: 80,
		},
	},
	internshipBackground: {
		backgroundColor: "#AB47BC",
		color: "#FFF",
		[theme.breakpoints.up("sm")]: {
			width: 80,
		},
	},
	contestBackground: {
		backgroundColor: "#F50057",
		color: "#fff",
	},
	scholarshipBackground: {
		backgroundColor: "#4CAF50",
		color: "#fff",
	},
	listItem: {
        borderBottom: "1px solid #ccc",
		[theme.breakpoints.down("xs")]: {
			flexDirection: "column",
			alignItems: "stretch",
		},
	},
	avatarEmployer: {
		borderRadius: 0,
		[theme.breakpoints.down("xs")]: {
			display: "none",
		},
	},
});

class JobsTable extends Component {
	render() {
		const { classes, offers } = this.props;

		return offers.map((currentJob, i) => (
			<LinkContainer key={currentJob.id} to={`/job/j/${currentJob.id}`} exact>
				<ListItem button className={classes.listItem}>
					{/* <Chip
                    label={getTypeName(currentJob.typeId)}
                    className={getTypeBackground(classes, currentJob.typeId)}
                /> */}

					{currentJob.user.employer && currentJob.user.employer.companyLogo && (
						<Avatar
							className={classes.avatarEmployer}
							src={
								currentJob.user.employer.companyLogo
									? `/uploads/companiesLogos/${currentJob.user.id}/${currentJob.user.employer.companyLogo}`
									: currentJob.typeId == 1
									? "/uploads/companiesLogos/avatar_png.png"
									: "/uploads/companiesLogos/internship.png"
							}
						/>
					)}

					<ListItemText
						primary={currentJob.title}
						secondary={currentJob.user.employer.companyName}
						primaryTypographyProps={{ className: classes.title }}
						secondaryTypographyProps={{ className: classes.subtitle }}
					/>
				</ListItem>
			</LinkContainer>
		));
	}
}

function getTypeName(type) {
	switch (type) {
		case 1:
			return "работа";
		case 2:
			return "стаж";
		case 3:
			return "конкурс";
		case 4:
			return "стипендия";
	}
}

function getTypeBackground(classes, type) {
	switch (type) {
		case 1:
			return classes.jobBackground;
		case 2:
			return classes.internshipBackground;
		case 3:
			return classes.contestBackground;
		case 4:
			return classes.scholarshipBackground;
	}
}

export default withStyles(styles)(JobsTable);
