import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
	Paper,
	Grid,
	Card,
	CardContent,
	CardActions,
	CardHeader,
	Button,
	CircularProgress,
} from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { profileActionCreators } from "../../actions/profileActionCreators";
import { getLoggedUser } from "../../helpers";
import { LinkContainer } from "react-router-bootstrap";
import Divider from "@material-ui/core/Divider";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {
	InsertDriveFile,
	School,
	Phone,
	ContactSupport,
	ArrowDownward,
	AlternateEmail,
	PersonAdd,
	Person,
	Info,
} from "@material-ui/icons";
import JobsTable from "./JobsTable";
import { employmentActionCreators } from "../../actions/employmentActionCreators";
import EmploymentSearchResults from "../employment/EmploymentSearchResults";

const styles = (theme) => ({
	root: {
		flexGrow: 1,
		paddingTop: theme.spacing.unit * 2,
		[theme.breakpoints.down("xs")]: {
			marginLeft: -50,
			marginRight: -50,
		},
	},
	heading: {
		marginBottom: 40,
		marginTop: 40,
	},
});

class StudentReview extends Component {
	constructor(props) {
		super(props);
		this.state = {
			file: {},
			isEmploymentSearchOpen: false,
		};

		this.handleSearchEmployment = this.handleSearchEmployment.bind(this);
	}

	componentWillMount() {
		const studentId = this.props.match.params.id;
		this.props.requestStudent(studentId);
	}

	handleSearchEmployment() {
		this.setState({
			isEmploymentSearchOpen: true,
		});
		this.props.searchSurveys(this.props.student.school, this.props.student.fn);
		this.props.searchData(this.props.student.school, this.props.student.egn);
		this.props.searchMedia(
			this.props.student.school,
			`${this.props.student.fName} ${this.props.student.lName}`
		);
	}

	render() {
		const { classes } = this.props;
		const loggedUser = getLoggedUser();
		const student = this.props.student;

		return this.props.isLoading ? (
			<CircularProgress />
		) : (
			<div className={classes.root}>
				<Grid container spacing={0}>
					<Grid item xs={12}>
						<Paper>
							<CardHeader
								title={`${student.fName} ${student.mName} ${student.lName}`}
							/>
							<List component="nav">
								<ListItem>
									<Avatar>
										<InsertDriveFile />
									</Avatar>
									<ListItemText
										primary="Специалност"
										secondary={student.speciality}
										secondaryTypographyProps={{ variant: "headline" }}
									/>
								</ListItem>
								<Divider />
								<ListItem>
									<Avatar>
										<School />
									</Avatar>
									<ListItemText
										primary="Висше училище"
										secondary={student.school}
										secondaryTypographyProps={{ variant: "headline" }}
									/>
								</ListItem>
								<Divider />
								<ListItem>
									<Avatar>
										<AlternateEmail />
									</Avatar>
									<ListItemText
										primary="E-mail"
										secondary={student.email}
										secondaryTypographyProps={{ variant: "headline" }}
									/>
								</ListItem>
								<Divider />
								<ListItem>
									<Avatar>
										<Phone />
									</Avatar>
									<ListItemText
										primary="Телефон"
										secondary={student.tel ? student.tel : "Не е посочен"}
										secondaryTypographyProps={{ variant: "headline" }}
									/>
								</ListItem>
								<Divider />
								<ListItem>
									<Avatar>
										<ContactSupport />
									</Avatar>
									<ListItemText
										primary="Учащ"
										secondary={student.hasGraduated ? "Да" : "Не"}
										secondaryTypographyProps={{ variant: "headline" }}
									/>
								</ListItem>
								<Divider />
							</List>
							<Card>
								<CardActions>
									{student.autobiography ? (
										<Button
											variant="contained"
											color="primary"
											href={`/uploads/cvs/${student.id}/${student.autobiography}`}
											download
										>
											Свали автобиография <ArrowDownward />
										</Button>
									) : (
										<div>Студентът няма качена автобиография</div>
									)}
								</CardActions>
								<CardActions>
									<Button
										variant="contained"
										color="primary"
										href={`/student/profile/${student.id}`}
									>
										Редактиране на профила
									</Button>
								</CardActions>
								<CardActions>
									<Button
										variant="contained"
										color="primary"
										onClick={this.handleSearchEmployment}
									>
										Проследяване на реализацията
									</Button>
								</CardActions>
								<CardActions>
									<Button
										variant="contained"
										color="primary"
										href={`/employment?university=${student.school}&query=${student.fName}%20${student.lName}`}
									>
										Търсене по име в Проследяване на реализацията
									</Button>
								</CardActions>
							</Card>
							{this.state.isEmploymentSearchOpen && (
								<EmploymentSearchResults
									searchText={`${this.props.student.fName} ${this.props.student.lName}`}
								></EmploymentSearchResults>
							)}

							{student.jobsAppliedFor !== undefined &&
								student.jobsAppliedFor.length > 0 && (
									<Card>
										<CardHeader title="Обявите, по които е кандидатствал" />
										<List dense>
											<JobsTable
												offers={student.jobsAppliedFor.map((x) => x.jobOffer)}
											/>
										</List>
									</Card>
								)}
						</Paper>
					</Grid>
				</Grid>
			</div>
		);
	}
}

export default withStyles(styles, { withTheme: true })(
	connect(
		(state) => ({
			...state.profiles,
			...state.employment,
		}),
		(dispatch) =>
			bindActionCreators(
				{
					...profileActionCreators,
					...employmentActionCreators,
				},
				dispatch
			)
	)(StudentReview)
);
