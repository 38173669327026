import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { Field, reduxForm } from 'redux-form';
import validate from './validations/loginValidate';
import { renderTextField } from '../../../helpers';
import loginMiddleware from './validations/loginMiddleware';
import { VerifiedUser } from '@material-ui/icons';

const styles = theme => ({
});

class StudentLoginForm extends Component {
    constructor(props) {
        super(props)

        this.props.change("loginType", 'student');
    }

    render() {
        const { classes, handleSubmit } = this.props;

        return (
            <React.Fragment>
                <form className={classes.form}>
                    <Field
                        name="email"
                        fullWidth
                        component={renderTextField}
                        label="E-mail"
                    />
                    <Field
                        name="password"
                        type="password"
                        fullWidth
                        component={renderTextField}
                        label="Парола"
                        style={{ marginBottom: 10 }}
                    />
                    <Button
                        fullWidth
                        variant="contained"
                        type="submit"
                        color="primary"
                        className="next"
                        style={{ marginTop: 10 }}
                        onClick={handleSubmit(loginMiddleware)}
                    >
                        Вход
                </Button>
                </form>
            </React.Fragment>
      );
    }
}

export default withStyles(styles)(reduxForm({
    form: 'StudentLoginForm', // a unique identifier for this form
    destroyOnUnmount: true, // preserve form data
    validate
})(StudentLoginForm));