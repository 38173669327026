import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { contentActionCreators } from "../../actions/contentActionCreators";
import { withStyles } from "@material-ui/core";
import EditArticleForm from "./forms/EditArticleForm";
import { getLoggedUser } from "../../helpers";

const styles = theme => ({});

class EditArticle extends Component {
	constructor(props) {
		super(props);

		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleImageDrop = this.handleImageDrop.bind(this);
	}

	componentDidMount() {
		this.props.getArticle(this.props.match.params.id);
	}

	handleSubmit = e => {
		this.props.editArticle(this.props.articleToRead.imagesNames);
	};

	handleImageDrop = (e, filesToUpload) => {
		this.props.uploadImages(filesToUpload);
	};

	render() {
		const article = this.props.articleToRead;
		const user = getLoggedUser();
		const userId = user.id;

		return (
			<div>
				<h3>
					{article.pageTypeId === 1
						? "Редактиране на новина"
						: "Редактиране на събитие"}
				</h3>
				<EditArticleForm
					getArticle={article}
					initialValues={{
						id: article.id,
						pageTypeId: article.pageTypeId,
						title: article.title,
						content: article.content,
						heroImage: article.heroImage,
                        date: article.date,
                        dateFinish: article.dateFinish,
						eventLocation: article.location,
						createdOn: article.dateCreated,
						createdByUserId: article.authorUserId,
						timestamp: article.timestamp,
						imagesFiles: article.imagesFiles
					}}
					onSubmit={this.handleSubmit}
                    onDrop={this.handleImageDrop}
                    userId={userId}
				/>
			</div>
		);
	}
}

export default withStyles(styles)(
	connect(
		state => state.content,
		dispatch => bindActionCreators(contentActionCreators, dispatch)
	)(EditArticle)
);
