import React, { Component } from "react";
import {
	Grid,
	Typography,
	Button,
	RadioGroup,
	Radio,
	FormControlLabel,
	FormLabel,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { Field, reduxForm } from "redux-form";
import validate from "./validations/employerRegValidate";
import asyncValidate from "./validations/asyncValidate";
import { renderTextField, renderRadioBtn } from "../../../helpers";

const styles = (theme) => ({});

class EmployerCompanyDetails extends Component {
	render() {
		const {
			handleSubmit,
			previousPage,
			restrictFinishRegistration,
		} = this.props;

		return (
			<form onSubmit={handleSubmit}>
				<React.Fragment>
					<Typography variant="h6" gutterBottom align="center" style={{ fontWeight: "bold", margin: 24, borderBottom: "1px solid #ccc" }}>
						Данни за компанията
					</Typography>
					<Grid container spacing={8}>
						<Grid item xs={12} md={6}>
							<Field
								name="companyName"
								fullWidth
								component={renderTextField}
								label="Име на компанията"
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<Field
								name="eik"
								fullWidth
								component={renderTextField}
								label="ЕИК"
							/>
						</Grid>
						<br />

						<Grid item xs={12}>
							<Field
								name="managerName"
								fullWidth
								component={renderTextField}
								label="Лице представляващо компанията"
							/>
						</Grid>
						
						<Grid item xs={12}>
							<Field
								name="companyAddress"
								fullWidth
								component={renderTextField}
								label="Седалище и адрес на управление"
							/>
						</Grid>
						<Grid item xs={12}>
							<Field
								name="siteURL"
								fullWidth
								component={renderTextField}
								label="Интернет страница"
							/>
						</Grid>
						<Grid item xs={12} align="right">
							
						</Grid>
					</Grid>
				</React.Fragment>
			</form>
		);
	}
}

export default withStyles(styles)(
	reduxForm({
		form: "EmployerRegistrationForm", // a unique identifier for this form
		destroyOnUnmount: false, // preserve form data
		validate,
		asyncValidate,
	})(EmployerCompanyDetails)
);
