import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
	Grid,
	Paper,
	withStyles,
	List,
	ListItem,
	ListItemText,
	LinearProgress,
	TablePagination,
    FormControlLabel,
    Switch,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TableBody,
    TextField,
    TableFooter,
    CircularProgress,
    Button,
} from "@material-ui/core";
import { LinkContainer } from "react-router-bootstrap";
import { profileActionCreators } from "../../actions/profileActionCreators";
import ReactDOM from "react-dom";
import MUIDataTable from "mui-datatables";
import { Check } from "@material-ui/icons";
import { Close } from "@material-ui/icons";
import { debounce } from "lodash";
import moment from "moment";

const CustomTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
	},
	body: {
		fontSize: 14,
	},
}))(TableCell);

const styles = (theme) => ({
	root: {
		flexGrow: 1,
	},
	paper: {
		padding: theme.spacing.unit * 2,
		overflow: "hidden",
		textAlign: "justify",
	},
	coverImage: {
		width: "100%",
	},
	searchContainer: {
		padding: theme.spacing.unit * 2,
	},
});

class StudentsList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			searchQuery: "",
			sortByDate: false,
			sortByFacultyNumber: false,
		};
		this.debouncedSearch = debounce(this.search, 500);
	}

	componentDidMount() {
		this.props.requestAllStudents();
	}

	search = (searchText) => {
		this.setState({ searchQuery: searchText });
		this.props.requestAllStudents(
			this.props.page,
			this.props.count,
			searchText
		);
	};

	handleSort = (sortKey) => {
		let sortDirection = "asc";
		if (this.state[sortKey]) sortDirection = "desc";
		this.setState((prevState) => ({
			sortByDate: false,
			sortByFacultyNumber: false,
			[sortKey]: !prevState[sortKey],
		}));
		this.props.requestAllStudents(
			this.props.page,
			this.props.count,
			this.state.searchQuery,
			sortKey,
			sortDirection
		);
	};

	pageChange = (event, page) => {
		if (this.props.page === page) return;
		this.props.requestAllStudents(page, this.props.count);
	};

	render() {
		const { classes, allStudents, countStudents, isLoading } = this.props;

		return (
			<div className={classes.root}>
				<Grid container spacing={24}>
                    <Grid item xs={12}>
                        <h1>Списък на студентите</h1>
                    </Grid>

					<Grid item xs={3}>
						<Paper className={classes.paper}>
							<h2>Търсене</h2>
							<Grid container spacing={24} className={classes.searchContainer}>
								<Grid item xs={12}>
									<TextField
										id="search-name"
										label="Търсене"
										onChange={(e) => this.debouncedSearch(e.target.value)}
										fullWidth
									/>
								</Grid>
								<Grid item xs={6}>
									<FormControlLabel
										control={
											<Switch
												checked={this.state.sortByDate}
												onChange={() => this.handleSort("sortByDate")}
												name="sortByDate"
											/>
										}
										label="Сортиране по дата на регистрация"
									/>
								</Grid>
								<Grid item xs={6}>
									<FormControlLabel
										control={
											<Switch
												checked={this.state.sortByFacultyNumber}
												onChange={() => this.handleSort("sortByFacultyNumber")}
												name="sortByFacultyNumber"
											/>
										}
										label="Сортиране по факултетен номер"
									/>
								</Grid>
							</Grid>
						</Paper>
					</Grid>

					{isLoading && (
						<Grid item xs={9}>
							<CircularProgress />
						</Grid>
					)}

					{!isLoading && (
						<Grid item xs={9}>
							<Table>
								<TableHead>
									<TableRow>
										<CustomTableCell>ID</CustomTableCell>
										<CustomTableCell>Име</CustomTableCell>
										<CustomTableCell>факултетен номер</CustomTableCell>
										<CustomTableCell>ВУЗ</CustomTableCell>
										<CustomTableCell>Специалност</CustomTableCell>
										<CustomTableCell>Дата на регистрация</CustomTableCell>
										<CustomTableCell>Действия</CustomTableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{allStudents.map((student, index) => (
										<TableRow key={index}>
											<TableCell>{student.userId}</TableCell>
											<TableCell>{`${student.fName} ${student.mName} ${student.lName}`}</TableCell>
											<TableCell>{student.facultyNumber}</TableCell>
											<TableCell>{student.university && student.university.name}</TableCell>
											<TableCell>{student.speciality}</TableCell>
											<TableCell>
												{student.userCreatedOn
													? moment(student.userCreatedOn).format(
															"DD.MM.YYYY HH:mm"
													  )
													: ""}
											</TableCell>
											<TableCell>
												<LinkContainer to={`/student/profile/${student.userId}`}>
                                                   <Button variant="contained" color="primary">
                                                       Преглед
                                                    </Button>
                                                </LinkContainer>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
							<TablePagination
								component="div"
								count={countStudents}
						        rowsPerPageOptions={[200]}
								page={this.props.page}
								onChangePage={this.pageChange}
                                RowsPerPageComponent={() => null}
                                labelDisplayedRows={({ from, to, count }) => ``}
							/>
						</Grid>
					)}
				</Grid>
			</div>
		);
	}
}

export default withStyles(styles)(
	connect(
		(state) => state.profiles,
		(dispatch) => bindActionCreators(profileActionCreators, dispatch)
	)(StudentsList)
);
