import React, { Component } from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { Field, reduxForm } from "redux-form";
import validate from "./validations/employerRegValidate";
import asyncValidate from "./validations/asyncValidate";
import { renderTextField, renderSingleCheckboxField } from "../../../helpers";

const styles = (theme) => ({
	link: {
		color: theme.palette.primary.main,
	},
});

class EmployerBaseForm extends Component {
	render() {
		const { handleSubmit, classes, restrictFinishRegistration } = this.props;

		return (
			<form onSubmit={handleSubmit}>
				<React.Fragment>
					<Typography
						variant="h6"
						gutterBottom
						align="center"
						style={{
							fontWeight: "bold",
							margin: 24,
							borderBottom: "1px solid #ccc",
						}}
					>
						Потребителски профил
					</Typography>
					<Grid container spacing={24}>
						<Grid item xs={12}>
							<Field
								name="email"
								fullWidth
								component={renderTextField}
								label="E-mail"
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<Field
								name="password"
								type="password"
								component={renderTextField}
								label="Парола"
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<Field
								fullWidth
								name="confirmPassword"
								type="password"
								component={renderTextField}
								label="Повторете парола"
							/>
						</Grid>
						<Grid item xs={12}>
							<Field
								name="hasAgreedToGdpr"
								type="checkbox"
								component={renderSingleCheckboxField}
								label={
									<a
										href="#"
										target="_blank"
										rel="noopener noreferrer"
										className={classes.link}
									>
										Съгласен съм с правилата за съхранение и защита на личните
										данни
									</a>
								}
							/>
						</Grid>
						<Grid item xs={12} align="right">
							
						</Grid>
					</Grid>
				</React.Fragment>
			</form>
		);
	}
}

export default withStyles(styles)(
	reduxForm({
		form: "EmployerRegistrationForm", // a unique identifier for this form
		destroyOnUnmount: false, // preserve form data
		initialValues: {},
		validate,
		asyncValidate,
	})(EmployerBaseForm)
);
