import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { LinkContainer } from 'react-router-bootstrap';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import './Storybook.css';
import Typography from '@material-ui/core/Typography';
import { Work } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { contentActionCreators } from '../actions/contentActionCreators'
import { bindActionCreators } from 'redux';
import { CircularProgress, Card, CardContent } from '@material-ui/core';
import Moment from 'react-moment';
import { getLoggedUser } from '../helpers';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
    card: {
        marginBottom: 24,
        [theme.breakpoints.down("xs")]: {
            marginLeft: -50,
            marginRight: -50
        }
    },
    heading: {
        color: theme.palette.primary.dark,
        [theme.breakpoints.down("xs")]: {
            marginLeft: -50
        }
    },
    eventCard: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "100%",
    },
    date: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "20%",
        backgroundColor: theme.palette.primary.dark,
        color: "white",
        padding: theme.spacing.unit * 2,
        borderRadius: 4,
    },
    eventDetails: {
        padding: theme.spacing.unit * 2,
    },
    link: {
        color: theme.palette.primary.dark,
        textDecoration: "none",
        "&:hover": {
            textDecoration: "underline",
        },
    },
});

class EventsList extends Component {

    componentWillMount() {
        this.props.requestAllEvents();
    }

    render() {

        return (
            <div>{this.props.isLoading === false ? EventsElements(this.props) : <CircularProgress />}</div>
        )
    }
}

function EventsElements(props) {
    const { classes } = props;

    return (
        <div>
            <Typography variant="h4" gutterBottom className={classes.heading}>
                Предстоящи събития
            </Typography>

            {props.events.length > 0 ? (
                props.events.map(article => (
                    <div key={article.id} className={classes.eventCard}>
                        <div className={classes.date}>
                            <Moment format="DD.MM.YYYY" withTitle>
                                {article.date}
                            </Moment>
                        </div>
                        <div className={classes.eventDetails}>
                            <Typography variant="h5" component="h2">
                                <Link to={`/page/${article.id}`} className={classes.link}>{article.title}</Link>
                            </Typography>
                            <Typography variant="body2" component="p">
                                {article.eventLocation}
                            </Typography>
                        </div>
                    </div>
                ))
            ) : (
                <Card className="event-card">
                    <CardContent>
                        <Typography variant="body1" component="p">
                            Няма намерени събития.
                        </Typography>
                    </CardContent>
                </Card>
            )}
        </div>
    );
}

export default withStyles(styles)(connect(
    state => state.content,
    dispatch => bindActionCreators(contentActionCreators, dispatch)
)(EventsList));