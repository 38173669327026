import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
    VerticalTimeline,
    VerticalTimelineElement
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import "./Storybook.css";
import Typography from "@material-ui/core/Typography";
import { Work } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { contentActionCreators } from "../actions/contentActionCreators";
import { bindActionCreators } from "redux";
import { CircularProgress } from "@material-ui/core";
import Moment from "react-moment";
import { LinkContainer } from "react-router-bootstrap";
import Button from "@material-ui/core/Button";
import { getLoggedUser } from "../helpers";
import { Card, CardContent, Grid } from "@material-ui/core";

const styles = theme => ({
    root: {

    },
    heading: {
		color: theme.palette.primary.dark,
        textTransform: 'uppercase',
	},
    eventCard: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "100%",
    },
    date: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "20%",
        backgroundColor: theme.palette.primary.dark,
        color: "white",
        padding: theme.spacing.unit * 2,
        borderRadius: 4,
    },
    eventDetails: {
        padding: theme.spacing.unit * 2,
    },
    link: {
        color: theme.palette.primary.dark,
        textDecoration: "none",
        "&:hover": {
            textDecoration: "underline",
        },
    },
    
});

class Storybook extends Component {
    componentWillMount() {
        this.props.requestEvents();
    }

    render() {
        return (
            <div>
                {this.props.isLoading === false ? (
                    TimelineElements(this.props)
                ) : (
                        <CircularProgress />
                    )}
            </div>
        );
    }
}

function TimelineElements(props) {
    const { classes } = props;

    return (
        <div>
            <Typography variant="h5" gutterBottom className={classes.heading}>
                Предстоящи събития
			</Typography>

            {props.events.length > 0 ? (
                props.events.map(article => (
                    <div key={article.id} className={classes.eventCard}>
                        <div className={classes.date}>
                            <Moment format="DD.MM.YYYY" withTitle>
                                {article.date}
                            </Moment>
                            {article.dateFinish && 
                                <span>
                                    <Moment format=" - DD.MM.YYYY" withTitle>
                                        {article.dateFinish}
                                    </Moment>
                                </span>
                            }
                        </div>
                        <div className={classes.eventDetails}>
                            <Typography variant="h5" component="h2">
                                <Link to={`/page/${article.id}`} className={classes.link}>{article.title}</Link>
                            </Typography>
                            <Typography variant="body2" component="p">
                                {article.eventLocation}
                            </Typography>
                        </div>
                    </div>
                ))
            ) : (
                    <Card className="event-card">
                        <CardContent>
                            <Typography variant="body1" component="p">
                                Няма намерени събития.
                            </Typography>
                        </CardContent>
                    </Card>
                )}
            
        </div>
    );
}
function renderArticleBtns() {
    const loggedUser = getLoggedUser();
    const adminSignedIn =
        loggedUser && loggedUser.token && loggedUser.userType === "admin"
            ? true
            : false;

    return (
        <Grid container justify={"center"}>
            <Grid item xs={12} sm={8}>
                <LinkContainer style={{ marginTop: 15 }} to={`/EventsList`}>
                    <Button fullWidth variant="outlined" color="primary">
                        Вижте всички
					</Button>
                </LinkContainer>
                {adminSignedIn ? (
                    <LinkContainer style={{ marginTop: 15 }} to={`/create/article`}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            text-align="center">
                            Създаване
						</Button>
                    </LinkContainer>
                ) : null}
            </Grid>
        </Grid>
    );
}

export default withStyles(styles)(
    connect(
        state => state.content,
        dispatch => bindActionCreators(contentActionCreators, dispatch)
    )(Storybook)
);
