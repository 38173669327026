import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
	Grid,
	Paper,
	withStyles,
	Button,
	List,
	ListItem,
	ListItemText,
	Avatar,
	ListItemSecondaryAction,
	CircularProgress,
	LinearProgress,
	TablePagination,
	FormControlLabel,
	Switch,
	Table,
	TableCell,
	TableHead,
	TableRow,
	TableBody,
	TextField,
	TableFooter,
} from "@material-ui/core";
import { LinkContainer } from "react-router-bootstrap";
import Moment from "react-moment";
import { profileActionCreators } from "../../actions/profileActionCreators";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import { Warning, Label, WarningOutlined } from "@material-ui/icons";
import { debounce } from "lodash";

const styles = (theme) => ({
	root: {
		flexGrow: 1,
	},
	paper: {
		padding: theme.spacing.unit * 2,
		overflow: "hidden",
		textAlign: "justify",
	},
	coverImage: {
		width: "100%",
	},
	socialShare: {
		color: theme.palette.primary.main,
	},
});

const CustomTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
	},
	body: {
		fontSize: 14,
	},
}))(TableCell);

class EmployersList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sortByEik: false,
            sortByName: false
        };
    }

	componentWillMount() {
		this.props.requestEmployers(
			this.props.match.params.partnersOnly || 0,
			this.props.page || 1,
			this.props.count,
			"",
			this.props.match.params.partnersOnly == 1 ? 7 : 0,
			this.props.match.params.partnersOnly == 1 ? "asc" : "desc"
		);
	}

	componentWillReceiveProps(next) {
		if (next.match.params.partnersOnly === this.props.match.params.partnersOnly)
			return;
		this.props.requestEmployers(
			next.match.params.partnersOnly || 0,
			this.props.page || 1,
			this.props.count,
			"",
			next.match.params.partnersOnly == 1 ? 7 : 0,
			next.match.params.partnersOnly == 1 ? "asc" : "desc"
		);
	}

	handleApprove(employerId) {
		this.props.approveEmployer(employerId, this.props.requestApprovedEmployers);
	}

	pageChange = (event, page) => {
		if (this.props.page === page || page === 0) return;
		this.props.requestEmployers(
			this.props.match.params.partnersOnly || 0,
			page,
			200,
			"",
			this.props.match.params.partnersOnly == 1 ? 7 : 0,
			this.props.match.params.partnersOnly == 1 ? "asc" : "desc"
		);
	};

	debouncedSearch = debounce((searchText) => {
		this.props.requestEmployers(
			this.props.match.params.partnersOnly || 0,
			this.props.page || 1,
			this.props.count,
			searchText,
			this.props.match.params.partnersOnly == 1 ? 7 : 0,
			this.props.match.params.partnersOnly == 1 ? "asc" : "desc"
		);
	}, 500);

	search = (searchText) => {
		this.debouncedSearch(searchText);
	};

	sort = (column, direction) => {
        if (column === "1") {
            this.setState({ sortByName: !this.state.sortByName, sortByEik: false });
        } else {
            this.setState({ sortByEik: !this.state.sortByEik, sortByName: false });
        }

		this.props.requestEmployers(
			this.props.match.params.partnersOnly || 0,
			this.props.page,
			this.props.count,
			"",
			column,
			direction
		);
	};

	render() {
		const { classes } = this.props;
		const { page, count, employers, countEmployers } = this.props;

		return (
			<div className={classes.root}>
				<Grid container spacing={24}>
					<Grid item xs={12}>
						<h1>Списък с компании</h1>
					</Grid>

					<Grid item xs={3}>
						<Paper className={classes.paper}>
							<h2>Търсене</h2>
							<List>
								<ListItem>
									<TextField
										id="search-eik"
										label="Търсене по ЕИК"
										onChange={(e) => this.search(e.target.value)}
										fullWidth
									/>
								</ListItem>
								<ListItem>
									<TextField
										id="search-name"
										label="Търсене по име на компания"
										onChange={(e) => this.search(e.target.value)}
										fullWidth
									/>
								</ListItem>
								<ListItem>
									<FormControlLabel
										control={
											<Switch
												checked={this.state.sortByEik}
												onChange={(e) =>
													this.sort("2", e.target.checked ? "asc" : "desc")
												}
												name="sortEik"
											/>
										}
										label="Сортирай по ЕИК"
									/>
								</ListItem>
								<ListItem>
									<FormControlLabel
										control={
											<Switch
												checked={this.state.sortByName}
												onChange={(e) =>
													this.sort("1", e.target.checked ? "asc" : "desc")
												}
												name="sortName"
											/>
										}
										label="Сортирай по име на компания"
									/>
								</ListItem>
							</List>
						</Paper>
					</Grid>

					{employers !== undefined && (
						<Grid
							item
							xs={9}
							style={this.props.isLoading ? { opacity: 0.2 } : { opacity: 1 }}
						>
							{this.props.isLoading && <CircularProgress />}
							{renderEmployerList(
								employers,
								this.props.page,
								this.pageChange.bind(this),
								true,
								this.props.isLoading
							)}
						</Grid>
					)}
				</Grid>
			</div>
		);
	}
}

function renderEmployerList(
	employers,
	page,
	pageChange,
	canApproved,
	isLoading
) {
	if (employers === undefined) {
		return;
	}

	return (
		<Table>
			<TableHead>
				<TableRow>
					<CustomTableCell>ЕИК</CustomTableCell>
					<CustomTableCell>Име</CustomTableCell>
					<CustomTableCell>Уебсайт</CustomTableCell>
					<CustomTableCell>Телефон</CustomTableCell>
					<CustomTableCell>Адрес</CustomTableCell>
					<CustomTableCell>Действия</CustomTableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{employers &&
					employers.map((x, i) => (
						<TableRow key={i}>
							<TableCell>{x.eik}</TableCell>
							<TableCell>{x.companyName}</TableCell>
							<TableCell>{x.siteURL}</TableCell>
							<TableCell>{x.phone}</TableCell>
							<TableCell>{x.companyAddress}</TableCell>
							<TableCell>
								<LinkContainer to={`/company/profile/${x.userId}`}>
									<Button variant="contained" color="primary">
										Преглед
									</Button>
								</LinkContainer>
                                <LinkContainer to={`/company/review/${x.userId}`}>
                                    <Button variant="contained" color="secondary">
                                        Редактиране
                                    </Button>
                                </LinkContainer>
							</TableCell>
						</TableRow>
					))}
				{employers && employers.length === 0 && !isLoading && (
					<TableRow>
						<TableCell colSpan={6}>Няма намерени резултати</TableCell>
					</TableRow>
				)}
			</TableBody>
			<TableFooter>
				<TableRow>
					<TablePagination
						colSpan={6}
						count={employers.length}
						rowsPerPageOptions={[200]}
						page={page}
						onChangePage={pageChange}
						RowsPerPageComponent={() => null}
						labelDisplayedRows={({ from, to, count }) => ``}
					/>
				</TableRow>
			</TableFooter>
		</Table>
	);
}

export default withStyles(styles)(
	connect(
		(state) => state.profiles,
		(dispatch) => bindActionCreators(profileActionCreators, dispatch)
	)(EmployersList)
);
