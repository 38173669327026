const validate = (values, props) => {
    const errors = {}
    const requiredFields = [
        'fName',
        'mName',
        'lName',
        'facultyNumber',
        'degree',
        'school',
        'hasGraduated'
    ];

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Полето е задължително';
        }
    });

    const cvField = values["cvFile"];
    if (!props.cvName && (!cvField || !Array.isArray(cvField))) {
        errors["cvFile"] = 'Автобиографията е задължителна част от Вашия профил';
    }

    return errors
}

export default validate