import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import { AppBar, Tab, Tabs, Typography, Paper, Button, Grid, LinearProgress } from '@material-ui/core';
import toRenderProps from 'recompose/toRenderProps';
import withState from 'recompose/withState';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { profileActionCreators } from '../../../actions/profileActionCreators';
import EmployerBaseForm from './forms/EmployerBaseForm';
import EmployerDetailsForm from './forms/EmployerDetailsForm';
import EmployerCorespondForm from './forms/EmployerCorespondForm';
import { getLoggedUser } from '../../../helpers';


const WithState = toRenderProps(withState('anchorEl', 'updateAnchorEl', null));

function TabContainer({ children, dir }) {
    return (
        <Typography component="div" dir={dir}>
            {children}
        </Typography>
    );
}

const styles = theme => ({
    root: {
        paddingTop: theme.spacing.unit * 2,
    },
    heading: {
        marginBottom: 40,
        marginTop: 40,
    },
});

class EmployerProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTab: 0,
            file: {}
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeIndex = this.handleChangeIndex.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillMount() {
        const loggedUser = getLoggedUser();
        this.props.requestEmployerProfile(this.props.match.params.id === undefined ? loggedUser.id : this.props.match.params.id);
    }

    handleChange(event, selectedTab) {
        this.setState({ selectedTab });
    };

    handleChangeIndex(index) {
        this.setState({ selectedTab: index });
    };

    handleSubmit(formPart) {

        switch (formPart) {
            case 'base':
                this.props.changePassword('employer');
                break;
            case 'details':
                this.props.changeEmployerDetails(getLoggedUser().userType);
                break;
            case 'corespond':
                this.props.changeEmployerCorespond();
                break;
        }
    }

    render() {
        const { classes, theme } = this.props;
        const loggedUser = getLoggedUser();
        const employer = this.props.employer;

        if (!loggedUser && !loggedUser.token) {
            return (null);
        } else {

            return (this.props.isLoading ? <LinearProgress /> :
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                >
                    <Grid item xs={12}>
                        <Paper className={classes.root}>
                            <Typography variant="h4" align="center" className={classes.heading}>
                                Профил
                            </Typography>
                            <AppBar position="static" color="default">
                                <Tabs
                                    value={this.state.selectedTab}
                                    onChange={this.handleChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    fullWidth
                                >
                                    <Tab label="Данни за профила" />
                                    <Tab label="Данни за организацията" />
                                    <Tab label="Данни за кореспонденция" />
                                </Tabs>
                            </AppBar>

                            <SwipeableViews
                                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                index={this.state.selectedTab}
                                onChangeIndex={this.handleChangeIndex}
                            >
                                <TabContainer dir={theme.direction}>
                                    <Grid
                                        container
                                        spacing={0}
                                        direction="column"
                                        alignItems="center"
                                        justify="center"
                                    >
                                        <Grid item xs={12} sm={4}>
                                            <EmployerBaseForm
                                                initialValues={{
                                                    email: employer.email
                                                }}
                                                onSubmit={() => this.handleSubmit('base')} />
                                        </Grid>
                                    </Grid>
                                </TabContainer>
                                <TabContainer dir={theme.direction}>
                                    <Grid
                                        container
                                        spacing={0}
                                        direction="column"
                                        alignItems="center"
                                        justify="center"
                                    >
                                        <Grid item xs={12} sm={6}>
                                            <EmployerDetailsForm
                                                initialValues={{
                                                    email: employer.email,
                                                    companyName: employer.companyName,
                                                    eik: employer.bulstat,
                                                    managerName: employer.managerName,
                                                    companyAddress: employer.companyAddress,
                                                    siteURL: employer.website,
                                                    companyLogoFile: employer.companyLogoFile,
                                                    hasAgreement: employer.hasAgreement,
                                                    agreementStartDate: employer.agreementStartDate,
                                                    agreementEndDate: employer.agreementEndDate,
                                                    agreementCoordinatorName: employer.agreementCoordinatorName,
                                                    agreementCoordinatorEmail: employer.agreementCoordinatorEmail,
                                                    agreementHasActionPlan: employer.agreementHasActionPlan
                                                }}
                                                onChange={this.saveFile}
                                                onSubmit={() => this.handleSubmit('details')}
                                                userType={loggedUser.userType}
                                            />
                                        </Grid>
                                    </Grid>
                                </TabContainer>
                                <TabContainer dir={theme.direction}>
                                    <Grid
                                        container
                                        spacing={0}
                                        direction="column"
                                        alignItems="center"
                                        justify="center"
                                    >
                                        <Grid item xs={12} sm={6}>
                                            <EmployerCorespondForm
                                                initialValues={{
                                                    email: employer.email,
                                                    contactPersonName: employer.contactPersonName,
                                                    phone: employer.phone
                                                }}
                                                onSubmit={() => this.handleSubmit('corespond')}
                                            />
                                        </Grid>
                                    </Grid>
                                </TabContainer>
                            </SwipeableViews>
                        </Paper>
                    </Grid>
                </Grid>
            );
        }
    }
}

export default withStyles(styles, { withTheme: true })(connect(
    state => state.profiles,
    dispatch => bindActionCreators(profileActionCreators, dispatch)
)(EmployerProfile));