import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
	AppBar,
	Button,
	Grid,
	Paper,
	Input,
	Toolbar,
	Typography,
} from "@material-ui/core";
import toRenderProps from "recompose/toRenderProps";
import withState from "recompose/withState";
import SearchIcon from "@material-ui/icons/Search";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";
import common from "@material-ui/core/colors/common";
import ScrollTrigger from "react-scroll-trigger";
import { bindActionCreators } from "redux";
import { menuActionCreators } from "../actions/menuActionCreators";
import { authenticationActionCreators } from "../actions/authentication/authenticationActionCreators";
import { getLoggedUser } from "../helpers";
import {
	ExitToApp,
	Business,
	People,
	Domain,
	Ballot,
	Assignment,
	AssignmentInd,
	BusinessCenter,
	SupervisedUserCircle,
	SearchTwoTone,
	HomeRounded,
	CardMembershipRounded,
	EventBusyRounded,
	PeopleOutlineRounded,
	ContactSupportRounded,
	CalendarTodayRounded,
	ArrowForward,
	ArrowRightAlt,
	ChevronRight,
    ExitToAppRounded,
    SubdirectoryArrowLeft,
} from "@material-ui/icons";
import { jobsActionCreators } from "../actions/jobsActionCreators";
import { debounce } from "lodash";

const styles = (theme) => ({
	root: {
		flexGrow: 2,
	},
	grow: {
		flexGrow: 2,
	},
	toolbar: {
		[theme.breakpoints.down("sm")]: {
			display: "block",
			paddingTop: theme.spacing.unit * 2,
			paddingBottom: theme.spacing.unit * 2,
		},
	},
	userToolbar: {
		backgroundColor: "#333333",
	},
	menuButton: {
		[theme.breakpoints.down("sm")]: {
			display: "block",
		},
		fontWeight: "bold",
		paddingLeft: theme.spacing.unit * 3,
		paddingRight: theme.spacing.unit * 3,
		paddingTop: theme.spacing.unit * 3,
		paddingBottom: theme.spacing.unit * 3,

		"& div": {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
		},
	},
	mainFeaturedPostContent: {
		padding: `${theme.spacing.unit * 6}px ${theme.spacing.unit * 2}px`,
		[theme.breakpoints.up("md")]: {
			paddingRight: 0,
		},
		color: common.white,
		fontWeight: 700,
		textShadow: "2px 2px 2px #000",
		[theme.breakpoints.up("md")]: {
			padding: `${theme.spacing.unit * 10}px ${theme.spacing.unit * 2}px`,
		},
		[theme.breakpoints.down("sm")]: {
			fontSize: 25,
		},
	},
	mainFeaturedPost: {
		backgroundImage: 'url("/img/careerstz.png")',
		backgroundRepeat: "no-repeat",
		backgroundSize: "cover",
		backgroundPosition: "center center",
	},

	search: {
		position: "relative",
		borderRadius: theme.shape.borderRadius,
		backgroundColor: fade(theme.palette.common.white, 0.15),
		"&:hover": {
			backgroundColor: fade(theme.palette.common.white, 0.25),
		},
		marginLeft: 0,
		width: "100%",
		[theme.breakpoints.up("sm")]: {
			marginLeft: theme.spacing.unit,
			width: "auto",
		},
	},
	searchIcon: {
		width: theme.spacing.unit * 9,
		height: "100%",
		position: "absolute",
		pointerEvents: "none",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	inputRoot: {
		color: "inherit",
		width: "100%",
	},
	inputInput: {
		paddingTop: theme.spacing.unit,
		paddingRight: theme.spacing.unit,
		paddingBottom: theme.spacing.unit,
		paddingLeft: theme.spacing.unit * 10,
		transition: theme.transitions.create("width"),
		width: "100%",
		[theme.breakpoints.up("sm")]: {
			width: 120,
			"&:focus": {
				width: 200,
			},
		},
	},
	userMenu: {
		display: "flex",
		width: "100%",
		justifyContent: "flex-end",
		"& a, & button": {
			fontSize: "0.8rem",
		},
		[theme.breakpoints.down("sm")]: {
			display: "block",
			"& a": {
				display: "block",
				fontWeight: "bold",
			},
			"& button": {
				display: "block",
			},
		},
	},
});

const WithState = toRenderProps(withState("anchorEl", "updateAnchorEl", null));

class ButtonAppBar extends Component {
	constructor(props) {
		super(props);
		const loc = this.props.location.pathname.split("/");
		this.state = {
			fixed: false,
			locationPartOne: loc[1],
			locationPartTwo: loc[2],
		};
		this.getTypeId = this.getTypeId.bind(this);
		this.handleSearch = debounce(this.handleSearch.bind(this), 750);
		this.handleSearchInput = this.handleSearchInput.bind(this);
	}

	componentDidMount() {
		this.props.requestMenuElements("main");
	}

	componentDidUpdate(prevProps) {
		if (prevProps.location.pathname !== this.props.location.pathname) {
			const loc = this.props.location.pathname.split("/");
			this.setState({
				locationPartOne: loc[1],
				locationPartTwo: loc[2],
			});
		}
	}

	onEnterViewport = () => {
		this.setState({
			fixed: false,
		});
	};

	onExitViewport = () => {
		this.setState({
			fixed: true,
		});
	};

	handleLogout = (e) => {
		this.props.logout();
	};

	handleSearchInput = (e) => {
		let searchTerm = e.target.value;
		this.handleSearch(searchTerm);
	};

	handleSearch = (searchTerm) => {
		const { locationPartOne } = this.state;

		if (searchTerm.length > 0) {
			(locationPartOne === undefined ||
				locationPartOne === "" ||
				locationPartOne.toLowerCase() === "news") &&
				this.props.searchNews(searchTerm);
			(locationPartOne === undefined ||
				locationPartOne === "" ||
				locationPartOne.toLowerCase() === "events") &&
				this.props.searchEvents(searchTerm);
			locationPartOne.toLowerCase() === "jobs" &&
				this.props.searchJobOffers(searchTerm, this.getTypeId());
		} else {
			(locationPartOne === undefined ||
				locationPartOne === "" ||
				locationPartOne.toLowerCase() === "news" ||
				locationPartOne.toLowerCase() === "events") &&
				this.props.removeSearch();
			locationPartOne.toLowerCase() === "jobs" &&
				this.props.removeSearchJobOffer(this.getTypeId());
		}
	};

	getTypeId() {
		const types = { "": 1, contests: 3, scholarships: 4 };
		const { locationPartTwo } = this.state;
		return types[locationPartTwo] || 1;
	}

	renderUserMenu = (loggedUser) => {
		const { classes } = this.props;

		return (
			<div className={classes.userMenu}>
				{loggedUser &&
				loggedUser.token &&
				loggedUser.userType === "employer" ? (
					<div>
						<LinkContainer to="/company/offers">
							<Button color="inherit">
								<ChevronRight /> Нашите обяви
							</Button>
						</LinkContainer>
						<LinkContainer to="/company/profile">
							<Button color="inherit">
								<ChevronRight /> Профил на компанията
							</Button>
						</LinkContainer>
					</div>
				) : null}
				{loggedUser && loggedUser.token && loggedUser.userType === "student" ? (
					<div>
						<LinkContainer to="/student/profile">
							<Button color="inherit">
								<ChevronRight />
								Моят профил
							</Button>
						</LinkContainer>
						<LinkContainer to="/student/offers">
							<Button color="inherit">
								<ChevronRight />
								Моите обяви
							</Button>
						</LinkContainer>
					</div>
				) : null}
				{loggedUser && loggedUser.token && loggedUser.userType === "admin" ? (
					<div>
						<LinkContainer to="/company/list">
							<Button color="inherit">
								<ChevronRight />
								Компании
							</Button>
						</LinkContainer>
						<LinkContainer to="/students/list">
							<Button color="inherit">
								<ChevronRight />
								Студенти
							</Button>
						</LinkContainer>
					</div>
				) : null}
				<Button color="inherit" onClick={this.handleLogout}>
					<SubdirectoryArrowLeft />
					Изход
				</Button>
			</div>
		);
	};

	render() {
		const props = this.props;
		const { classes } = props;
		const { fixed } = this.state;
		const loggedUser = getLoggedUser();

		return (
			<ScrollTrigger
				onEnter={this.onEnterViewport}
				onExit={this.onExitViewport}
			>
				<div className={classes.root}>
					<Paper className={classes.mainFeaturedPost}>
						<Grid container style={{ justifyContent: "center" }}>
							<Grid
								item
								flexGrow={1}
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<img
									src="/img/logo.png"
									alt="logo"
									style={{ width: 140, height: 140 }}
								/>
								<Typography
									variant="display2"
									className={classes.mainFeaturedPostContent}
								>
									<Link to={"/"}>Кариерно развитие</Link>
								</Typography>
							</Grid>
						</Grid>
					</Paper>
					<AppBar position="relative">
						<Toolbar className={classes.toolbar}>
							<WithState>
								{({ anchorEl, updateAnchorEl }) => {
									const open = Boolean(anchorEl);
									const handleClose = () => {
										updateAnchorEl(null);
									};

									return (
										<React.Fragment>
											<LinkContainer to={"/"}>
												<Button color="inherit" className={classes.menuButton}>
													<div>
														<HomeRounded fontSize="large" color="secondary" />
														<span style={{ marginTop: "4px" }}>Начало</span>
													</div>
												</Button>
											</LinkContainer>
											<LinkContainer to={"/jobs"}>
												<Button color="inherit" className={classes.menuButton}>
													<div>
														<CardMembershipRounded
															fontSize="large"
															color="secondary"
														/>
														<span style={{ marginTop: "7px" }}>
															Обяви за работа
														</span>
													</div>
												</Button>
											</LinkContainer>
											<LinkContainer to={"/events"}>
												<Button color="inherit" className={classes.menuButton}>
													<div>
														<CalendarTodayRounded
															fontSize="large"
															color="secondary"
														/>
														<span style={{ marginTop: "7px" }}>Събития</span>
													</div>
												</Button>
											</LinkContainer>
											<LinkContainer to={"/page/8"}>
												<Button color="inherit" className={classes.menuButton}>
													<div>
														<PeopleOutlineRounded
															fontSize="large"
															color="secondary"
														/>
														<span style={{ marginTop: "7px" }}>Партньори</span>
													</div>
												</Button>
											</LinkContainer>
											<LinkContainer to={"/page/7"}>
												<Button color="inherit" className={classes.menuButton}>
													<div>
														<ContactSupportRounded
															fontSize="large"
															color="secondary"
														/>
														<span style={{ marginTop: "7px" }}>Контакти</span>
													</div>
												</Button>
											</LinkContainer>
											{/* {props.elements.map((element) => (
												<LinkContainer
													key={element.id}
													to={
														element.articleId != null
															? `/article/${element.articleId}`
															: element.link
													}
												>
													<Button
														color="inherit"
														className={classes.menuButton}
													>
														{element.title}
													</Button>
												</LinkContainer>
											))} */}
										</React.Fragment>
									);
								}}
							</WithState>
							{/* <div className={classes.search}>
								<div className={classes.searchIcon}>
									<SearchTwoTone />
								</div>
								<Input
									placeholder="Търсене"
									disableUnderline
									onChange={this.handleSearchInput}
									classes={{
										root: classes.inputRoot,
										input: classes.inputInput,
									}}
								/>
							</div> */}
							<div className={classes.grow} />
						</Toolbar>

						{this.props.showLogoutBtn || loggedUser ? (
							<Toolbar className={classes.userToolbar}>
								{this.renderUserMenu(loggedUser)}
							</Toolbar>
						) : null}
					</AppBar>
				</div>
			</ScrollTrigger>
		);
	}
}

ButtonAppBar.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(
	connect(
		(state) => {
			return { ...state.menu, ...state.routing };
		},
		(dispatch) =>
			bindActionCreators(
				{
					...menuActionCreators,
					...authenticationActionCreators,
					...jobsActionCreators,
				},
				dispatch
			)
	)(ButtonAppBar)
);
