import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import NavBar from "./NavBar";
import Footer from "./Footer";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { withStyles } from "@material-ui/core/styles";
import Notifier from "../helpers/Notifier";
import { AppBar, Toolbar, Button, Menu, MenuItem } from "@material-ui/core";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
	mainContent: {
		[theme.breakpoints.down("sm")]: {
			padding: 0,
		},
		[theme.breakpoints.up("sm")]: {
			padding: 16,
		},
		[theme.breakpoints.up("lg")]: {
			padding: 32,
		},
	},
	menuButton: {
		marginRight: 8,
	},
	mainFeaturedPost: {
		backgroundImage: 'url("/img/followup.jpg")',
		backgroundRepeat: "no-repeat",
		backgroundSize: "cover",
		backgroundPosition: "center center",
		minHeight: 300,
	},
	mainFeaturedPostContent: {
		textAlign: "center",
		color: "#fff",
		padding: 16,
		backgroundColor: "rgba(0, 0, 0, 0.5)",
	},
});

const Layout = (props) => {
	const { classes } = props;
	const [anchorEl, setAnchorEl] = useState(null);

	const handleMenuClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const handleLogout = () => {
		localStorage.removeItem("loggedUser");
		window.location.href = "/";
	};

	return (
		<Grid container spacing={0}>
			<Grid item xs={12}>
				<Grid
					container
					className={classes.mainFeaturedPost}
					style={{ justifyContent: "center" }}
				>
					<Grid
						item
						flexGrow={1}
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<Typography
							variant="display2"
							className={classes.mainFeaturedPostContent}
						>
							<Link to={"/"}>Проследяване на реализацията</Link>
						</Typography>
					</Grid>
				</Grid>
				<Grid container>
					<Grid item xs={12}>
						<AppBar
							position="relative"
							style={{ backgroundColor: "#333", color: "#fff" }}
						>
							<Toolbar>
								<div style={{ marginRight: 20 }}>
									<h3>Система за проследяване на реализацията</h3>
								</div>
								<LinkContainer to={"/followup"}>
									<Button
										variant="contained"
										color="primary"
										className={classes.menuButton}
									>
										Кариерно проследяване
									</Button>
								</LinkContainer>
								<LinkContainer to={"/students/list"}>
									<Button
										variant="contained"
										color="primary"
										className={classes.menuButton}
									>
										Студенти
									</Button>
								</LinkContainer>
								<LinkContainer to={"/company/list"}>
									<Button
										variant="contained"
										color="primary"
										className={classes.menuButton}
									>
										Работодатели
									</Button>
								</LinkContainer>
								<Button
									aria-controls="data-sources-menu"
									aria-haspopup="true"
									onClick={handleMenuClick}
									variant="contained"
									color="primary"
									className={classes.menuButton}
								>
									Източници на данни
								</Button>
								<Menu
									id="data-sources-menu"
									anchorEl={anchorEl}
									keepMounted
									open={Boolean(anchorEl)}
									onClose={handleMenuClose}
								>
									<MenuItem onClick={handleMenuClose}>
										<Link to="/followup/questionnaires">Анкети</Link>
									</MenuItem>
									<MenuItem onClick={handleMenuClose}>
										<Link to="/followup/articles">Интернет</Link>
									</MenuItem>
									<MenuItem onClick={handleMenuClose}>
										<Link to="/followup/data">НОИ</Link>
									</MenuItem>
								</Menu>
								<LinkContainer to={"/followup/analysis"}>
									<Button
										variant="contained"
										color="primary"
										className={classes.menuButton}
									>
										Дискусии
									</Button>
								</LinkContainer>
								<Button
									variant="contained"
									color="secondary"
									className={classes.menuButton}
									style={{ marginLeft: 18 }}
									onClick={handleLogout}
								>
									Изход
								</Button>
							</Toolbar>
						</AppBar>
					</Grid>
				</Grid>
				<Notifier />
			</Grid>
			<Grid
				item
				xs={12}
				className={classes.mainContent}
				style={{ backgroundColor: "#ddd" }}
			>
				{props.children}
			</Grid>

			<Grid item xs={12}>
				<Footer />
			</Grid>

			<CookieConsent
				buttonText="Съгласен съм"
				buttonStyle={{ backgroundColor: "#066" }}
				declineButtonText="Не съм съгласен"
				enableDeclineButton
				declineButtonStyle={{ backgroundColor: "#666" }}
			>
				Използваме бисквитки за нуждите на функционирането на сайта.{" "}
				<a href="#" style={{ color: "#efefef" }}>
					Повече информация
				</a>
			</CookieConsent>
		</Grid>
	);
};

export default withStyles(styles)(Layout);
