export const employmentConstants = {
    REQUEST_CREATE_SURVEY_BEGIN: 'REQUEST_CREATE_SURVEY_BEGIN',
    CREATE_SURVEY_SUCCESS: 'CREATE_SURVEY_SUCCESS',
    CREATE_SURVEY_ERROR: 'CREATE_SURVEY_ERROR',
    REQUEST_CREATE_DATA_BEGIN: 'REQUEST_CREATE_DATA_BEGIN',
    CREATE_DATA_SUCCESS: 'CREATE_DATA_SUCCESS',
    CREATE_DATA_ERROR: 'CREATE_DATA_ERROR',
    REQUEST_CREATE_MEDIA_BEGIN: 'REQUEST_CREATE_MEDIA_BEGIN',
    CREATE_MEDIA_SUCCESS: 'CREATE_MEDIA_SUCCESS',
    CREATE_MEDIA_ERROR: 'CREATE_MEDIA_ERROR',
    SEARCH_SURVEYS_BEGIN: 'SEARCH_SURVEYS_BEGIN',
    SEARCH_SURVEYS: 'SEARCH_SURVEYS',
    SEARCH_DATA_BEGIN: 'SEARCH_DATA_BEGIN',
    SEARCH_DATA: 'SEARCH_DATA',
    SEARCH_MEDIA_BEGIN: 'SEARCH_MEDIA_BEGIN',
    SEARCH_MEDIA: 'SEARCH_MEDIA',
};