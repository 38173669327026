import React, { Component } from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { Field, reduxForm } from "redux-form";
import validate from "./validations/employerRegValidate";
import asyncValidate from "./validations/asyncValidate";
import { renderTextField } from "../../../helpers";

const styles = (theme) => ({});

class EmployerCorrespondDetails extends Component {
	render() {
		const { handleSubmit, previousPage } = this.props;

		return (
			<form onSubmit={handleSubmit}>
				<React.Fragment>
					<Typography
						variant="h6"
						gutterBottom
						align="center"
						style={{
							fontWeight: "bold",
							margin: 24,
							borderBottom: "1px solid #ccc",
						}}
					>
						Данни за връзка с компанията
					</Typography>
					<Grid container spacing={24}>
						<Grid item xs={12} md={6}>
							<Field
								name="contactPersonName"
								fullWidth
								component={renderTextField}
								label="Лице за кореспонденция"
							/>
						</Grid>

						<Grid item xs={12} md={4}>
							<Field
								name="phone"
								fullWidth
								component={renderTextField}
								label="Телефон"
							/>
						</Grid>
						<Grid item xs={12} align="right">
							<Button
								variant="contained"
								color="secondary"
								style={{ marginRight: 10 }}
								onClick={previousPage}
								className="next"
								align="inline"
							>
								Назад
							</Button>
							<Button
								variant="contained"
								type="submit"
								color="primary"
								className="next"
								align="inline"
							>
								Регистрация
							</Button>
						</Grid>
					</Grid>
				</React.Fragment>
			</form>
		);
	}
}

export default withStyles(styles)(
	reduxForm({
		form: "EmployerRegistrationForm", // a unique identifier for this form
		destroyOnUnmount: false, // preserve form data
		validate,
		asyncValidate,
	})(EmployerCorrespondDetails)
);
