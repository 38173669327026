import { push } from "react-router-redux";
import {
	authHeader,
	getConvertedBase64Images,
	handleResponse,
	handleError,
} from "../helpers";
import { employmentConstants } from "../constants/employmentConstants";
import { notify } from "../helpers/notification";
import { config } from "../helpers/config";

export const employmentActionCreators = {
	createSurvey: (surveyData) => async (dispatch) => {
		dispatch({ type: employmentConstants.REQUEST_CREATE_SURVEY_BEGIN });

		const url = `${config.base}api/Employment/CreateSurvey`;
		fetch(url, {
			method: "POST",
			headers: authHeader(),
			body: JSON.stringify(surveyData),
		}).then((res) => {
			if (res.ok) {
				dispatch({ type: employmentConstants.CREATE_SURVEY_SUCCESS });
				dispatch(push("/employment"));
				dispatch(notify({ message: "Данните са качени успешно", status: 200 }));
			} else {
				dispatch({ type: employmentConstants.CREATE_SURVEY_ERROR });
				dispatch(
					notify({
						message: "Възникнала е грешка, моля опитайте отново.",
						status: 400,
					})
				);
			}
		});
	},

	createData: (data) => async (dispatch) => {
		dispatch({ type: employmentConstants.REQUEST_CREATE_DATA_BEGIN });

		const url = `${config.base}api/Employment/CreateData`;
		fetch(url, {
			method: "POST",
			headers: authHeader(),
			body: JSON.stringify(data),
		}).then((res) => {
			if (res.ok) {
				dispatch({ type: employmentConstants.CREATE_DATA_SUCCESS });
				dispatch(push("/employment"));
				dispatch(notify({ message: "Данните са качени успешно", status: 200 }));
			} else {
				dispatch({ type: employmentConstants.CREATE_DATA_ERROR });
				dispatch(
					notify({
						message: "Възникнала е грешка, моля опитайте отново.",
						status: 400,
					})
				);
			}
		});
	},

	createMedia: (mediaData) => async (dispatch) => {
		dispatch({ type: employmentConstants.REQUEST_CREATE_MEDIA_BEGIN });

		const url = `${config.base}api/Employment/CreateMedia`;
		fetch(url, {
			method: "POST",
			headers: authHeader(),
			body: JSON.stringify(mediaData),
		}).then((res) => {
			if (res.ok) {
				dispatch({ type: employmentConstants.CREATE_MEDIA_SUCCESS });
				dispatch(push("/employment"));
				dispatch(notify({ message: "Данните са качени успешно", status: 200 }));
			} else {
				dispatch({ type: employmentConstants.CREATE_MEDIA_ERROR });
				dispatch(
					notify({
						message: "Възникнала е грешка, моля опитайте отново.",
						status: 400,
					})
				);
			}
		});
	},

	searchSurveys: (university, query) => async (dispatch) => {
		dispatch({ type: employmentConstants.SEARCH_SURVEYS_BEGIN });

		const url = `${config.base}api/Employment/SearchSurveys?query=${query}&university=${university}`;
		const response = await fetch(url, {
			headers: authHeader(),
		});

		const surveys = await response.json();

		dispatch({ type: employmentConstants.SEARCH_SURVEYS, payload: surveys });
	},

	searchData: (university, query) => async (dispatch) => {
		dispatch({ type: employmentConstants.SEARCH_DATA_BEGIN });

		const url = `${config.base}api/Employment/SearchData?query=${query}&university=${university}`;
		const response = await fetch(url, {
			headers: authHeader(),
		});

		const data = await response.json();

		dispatch({ type: employmentConstants.SEARCH_DATA, payload: data });
	},

	searchMedia: (university, query) => async (dispatch) => {
		dispatch({ type: employmentConstants.SEARCH_MEDIA_BEGIN });

		const url = `${config.base}api/Employment/SearchMedia?query=${query}&university=${university}`;
		const response = await fetch(url, {
			headers: authHeader(),
		});

		const media = await response.json();

		dispatch({ type: employmentConstants.SEARCH_MEDIA, payload: media });
	},
};
