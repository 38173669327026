import React from 'react';
import { FormHelperText, FormControlLabel, Switch, Checkbox } from "@material-ui/core";

export const renderSingleCheckboxField = ({ input, label, meta: { touched, error }, ...custom }) => {

    const { name, onChange } = input;
    const inputValue = input.value || false;

    return (
        <div>
            <div>
                <FormControlLabel
                    key={`checkbox-${name}`}
                    control={
                        <Checkbox
                            color="primary"
                            name={`${name}`}
                            checked={inputValue}
                            onChange={onChange}
                        />
                    }
                    label={label}
                />
            </div>
            <FormHelperText error={error && touched}>{touched && error}</FormHelperText>
        </div>
    );
};