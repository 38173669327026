import React, { Component } from "react";
import { Grid, Button, MenuItem, InputLabel } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { Field, reduxForm } from "redux-form";
import validate from "./validations/studentDetailsValidation";
import { renderTextField, renderSelectField, renderError, renderDropzoneDocField } from "../../../../helpers";

const styles = (theme) => ({});

class StudentDetailsForm extends Component {
	render() {
		const { handleSubmit, universities } = this.props;
		const cvName = this.props.cvName;

		return (
			<form onSubmit={handleSubmit}>
				<React.Fragment>
					<Grid container spacing={24} style={{ marginTop: 40, marginBottom: 10 }}>
						<Grid item xs={12} md={6}>
							<Field name="fName" component={renderTextField} label="Име" />
						</Grid>
						<Grid item xs={12} md={6}>
							<Field name="mName" component={renderTextField} label="Презиме" />
						</Grid>
						<Grid item xs={12}>
							<Field name="lName" component={renderTextField} label="Фамилия" />
						</Grid>
						<Grid item xs={12}>
							<Field
								name="facultyNumber"
								fullWidth
								component={renderTextField}
								label="Факултетен номер"
							/>
						</Grid>
						<Grid item xs={12}>
							<Field
								name="degree"
								component={renderSelectField}
								label="Образователно-квалификационна степен">
								<MenuItem value="Бакалавър">Бакалавър</MenuItem>
								<MenuItem value="Професионален бакалавър">Професионален бакалавър</MenuItem>
								<MenuItem value="Магистър">Магистър</MenuItem>
							</Field>
						</Grid>
						<Grid item xs={12} md={6}>
							<Field name="universityId" component={renderSelectField} label="Висше училище">
								{universities &&
									universities.map((x) => (
										<MenuItem key={x.id} value={x.id}>
											{x.name}
										</MenuItem>
									))}
							</Field>
						</Grid>
						<Grid item xs={12} md={6}>
							<Field name="speciality" component={renderTextField} label="Специалност" />
						</Grid>
						<Grid item xs={12}>
							<Field name="tel" component={renderTextField} label="Телефонен номер" />
						</Grid>
						<Grid item xs={12}>
							<label>
								<Field
									color="primary"
									name="hasGraduated"
									component="input"
									type="radio"
									value="true"
								/>{" "}
								Учащ
							</label>
							<label>
								<Field name="hasGraduated" component="input" type="radio" value="false" /> Завършил
							</label>
							<Field type="text" name="hasGraduated" component={renderError} />
						</Grid>
						<Grid item xs={12}>
							<InputLabel>Качете вашето CV</InputLabel>
							<Field name="cvFile" cvName={cvName} component={renderDropzoneDocField} />
						</Grid>
						<Grid item xs={12} align="right">
							<Button variant="contained" type="submit" color="primary" className="next" align="right">
								Редактирай
							</Button>
						</Grid>
					</Grid>
				</React.Fragment>
			</form>
		);
	}
}

export default withStyles(styles)(
	reduxForm({
		form: "StudentDetailsForm", // a unique identifier for this form
		destroyOnUnmount: false, // preserve form data
		validate,
		enableReinitialize: true,
	})(StudentDetailsForm)
);
